import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { iif, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import {
  ConfirmationDialogConfig,
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  DialogResult,
  Indicator,
  ItemType,
  Period,
  PlatformValueGroupSetStatus,
  ReportIntegrationType,
  SOURCE_CONFIGURATION,
  Status,
  ValueGroup,
} from '../../../models';
import { TranslateService } from '../../../services/common/translate/translate.service';
import { ResetValueOptions, ValueFormControl } from '../../models/valueFormControl';
import { UpsertValue } from '../../models/upsertValue';
import { MetricEditorNumericFieldComponent } from '../metric-editor-numeric-field/metric-editor-numeric-field.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { FormUtils } from '../../../classes';
import { isNullishValue } from '../../utils/valueUtils';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';

interface FrequencyValueAndControl {
  newValue: unknown;
  control: ValueFormControl;
}

@Component({
  selector: 'lib-metric-editor-frequency-handler',
  templateUrl: './metric-editor-frequency-handler.component.html',
  styleUrls: ['./metric-editor-frequency-handler.component.scss'],
})
export class MetricEditorFrequencyHandlerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) valueFormControl!: ValueFormControl;
  @Input({ required: true }) valueGroupFormGroup!: ValueGroupFormGroup;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input({ required: true }) valueGroup!: ValueGroup;
  @Input() indicator?: Indicator;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Input() integrationType: ReportIntegrationType | null = null;
  @Input() disableFrequencyFields: boolean = false;
  @Input() fiscalYearPeriod?: Period;

  @Output() update: EventEmitter<UpsertValue> = new EventEmitter<UpsertValue>();
  @Output() resetValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('valueFieldRef') valueFieldRef?: MetricEditorNumericFieldComponent;

  frequenciesControls: ValueFormControl[] = [];

  readonly eItemType = ItemType;

  private destroy$ = new Subject<void>();
  private frequencyValueChanges$ = new Subject<FrequencyValueAndControl>();
  private frequencyValueChangesSubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private readonly dialogService: DialogsService,
  ) {}

  ngOnInit(): void {
    this.frequenciesControls = this.valueGroupFormGroup.getFrequenciesControlForValueFormControl(this.valueFormControl);
    this.setupHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onBlur(): void {}

  private setupHandler() {
    this.setupFrequencyValueChangesSubscription();
    this.setupFormControlChangesSubscription();
    this.setupValueResetsSubscription();
  }

  private setupFrequencyValueChangesSubscription(): void {
    this.frequencyValueChangesSubscription?.unsubscribe();
    this.frequencyValueChangesSubscription = this.frequencyValueChanges$
      .pipe(
        mergeMap((value: FrequencyValueAndControl) =>
          iif(
            () => !isNullishValue(value.newValue),
            this.handleUpdateFrequencyValue(value.newValue, value.control),
            this.handleImplicitResetValue(value.newValue, value.control),
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setupFormControlChangesSubscription(): void {
    this.frequenciesControls.forEach((frequencyControl) => {
      frequencyControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: (value) => this.frequencyValueChanges$.next({ newValue: value, control: frequencyControl }),
      });
    });
  }

  private handleUpdateFrequencyValue(
    newValue: unknown,
    frequencyValueFormControl: ValueFormControl,
  ): Observable<boolean> {
    return of(newValue).pipe(
      filter(() => frequencyValueFormControl.valid),
      switchMap((newValue: unknown) => {
        this.emitUpdate(newValue, frequencyValueFormControl);
        return of(true);
      }),
    );
  }

  private handleImplicitResetValue(value: unknown, frequencyValueFormControl: ValueFormControl): Observable<unknown> {
    return of(value).pipe(tap(() => frequencyValueFormControl.resetValue({ shouldPrompt: false })));
  }

  private setupValueResetsSubscription(): void {
    this.frequenciesControls.forEach((frequencyControl) => {
      frequencyControl.valueResets
        .pipe(
          switchMap((options: ResetValueOptions | undefined) =>
            options?.shouldPrompt ? this.promptResetValueConfirmationDialog() : of(true),
          ),
          filter(Boolean),
          tap(() => frequencyControl.reset()),
          tap(() => {
            if (!FormUtils.isNullOrEmpty(frequencyControl.valueRef.id)) {
              this.resetValue.emit(frequencyControl.valueRef.id);
            }
          }),
          tap(() => this.setupFrequencyValueChangesSubscription()),
          takeUntil(this.destroy$),
        )
        .subscribe();
    });
  }

  private promptResetValueConfirmationDialog(): Observable<boolean> {
    return this.dialogService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          warningMsg: this.translateService.instant(
            'Are you sure you want to reset the value of this field? This cannot be undone',
          ),
          primaryBtn: this.translateService.instant('Reset'),
        },
      })
      .afterClosed()
      .pipe(map((result: DialogResult | undefined) => result?.status === Status.CONFIRMED));
  }

  private emitUpdate(newValue: unknown, valueFormControl: ValueFormControl): void {
    this.handlePendingCreation(valueFormControl);
    this.update.emit(valueFormControl.toUpsertValue(newValue, this.valueFormControl.valueRef));
  }

  private handlePendingCreation(valueFormControl: ValueFormControl): void {
    if (valueFormControl.valueRef.id == null || valueFormControl.valueRef.id === '') {
      valueFormControl.waitForNextUpdate();
    }
  }
}
