<ng-template #template let-item="item">
  <ng-container
    *libFeatureFlag="{ featureFlags: item.featureFlag, hiddenWhenEnabled: item.featureFlagHiddenWhenEnabled }"
  >
    <ng-container *libPermission="item.permissions">
      <ng-container *ngIf="!item.showCondition || (rowData | executeFunctionOnValue: item.showCondition)">
        <p-button
          *ngIf="!item.isSeparator; else menuSeparator"
          styleClass="text-left w-100"
          [text]="true"
          [icon]="'pi pi-' + item.icon"
          [label]="item.label"
          severity="secondary"
          (click)="item.onClick(rowData)"
        >
        </p-button>

        <ng-template #menuSeparator>
          <lib-divider></lib-divider>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<lib-popup-menu
  *ngIf="rowData | doesActionMenuHasItems: actionMenuItems"
  [template]="template"
  [menuItems]="actionMenuItems"
>
</lib-popup-menu>
