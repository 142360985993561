import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  private _cache: {
    [key: string]: {
      value: any;
      expiry: number;
    };
  } = {};

  set(key: string, value: any, expires: string = '0'): void {
    let expiresInSeconds: number;
    if (expires === '') {
      expiresInSeconds = 0;
    } else if (expires === '0') {
      expiresInSeconds = 60 * 60 * 24; // Max cache expiration 24h
    } else if (expires.endsWith('s')) {
      expiresInSeconds = Number(expires.replace('s', ''));
    } else if (expires.endsWith('m')) {
      expiresInSeconds = 60 * Number(expires.replace('m', ''));
    } else if (expires.endsWith('h')) {
      expiresInSeconds = 60 * 60 * Number(expires.replace('h', ''));
    } else {
      throw new Error(`Cache expiry invalid. Valid formats: 10s, 5m, 1h`);
    }
    this._cache[key] = {
      value,
      expiry: Date.now() + expiresInSeconds * 1000,
    };
  }

  get<T = any>(key: string): T | undefined {
    return Object.keys(this._cache).indexOf(key) >= 0 && Date.now() <= this._cache[key].expiry
      ? this._cache[key].value
      : undefined;
  }

  clear(key: string): void {
    if (Object.keys(this._cache).indexOf(key) >= 0) {
      delete this._cache[key];
    }
  }

  clearKeysWithPrefix(prefix: string): void {
    Object.keys(this._cache).forEach((key) => {
      if (key.startsWith(prefix)) {
        this.clear(key);
      }
    });
  }

  clearCategory(category: string): void {
    Object.keys(this._cache).forEach((key) => {
      if (key.startsWith(category + ':')) {
        this.clear(key);
      }
    });
  }

  getCategory(category: string): any {
    const key = Object.keys(this._cache).find((key) => key.startsWith(category + ':'));
    return key ? this._cache[key].value : [];
  }

  clearAll(): void {
    this._cache = {};
  }
}
