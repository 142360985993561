import { Injectable } from '@angular/core';
import { ApiResponse, ApplicationApiDefinition, DataRequest } from '../../../models';
import { ApiService } from '../../common';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClientDataRequestFrequencyGroupsService {
  apiName: keyof ApplicationApiDefinition = 'collect';
  resource: string;
  servicePath: string;

  constructor(private apiService: ApiService) {
    this.servicePath = apiService.getServicePath(this.apiName);
    this.resource = this.apiService.apiConfig.apis.collect.resources.dataRequestFrequencyGroups;
  }

  public createNextPeriodDataRequest(dataRequestFrequencyGroupId: string): Observable<ApiResponse<DataRequest>> {
    return this.apiService.post(
      `${this.servicePath}${this.resource}/data_request_frequency_groups/${dataRequestFrequencyGroupId}/create_next_period`,
    );
  }
}
