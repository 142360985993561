<p class="ytd-label">{{ indicatorName }}</p>

<ng-container *ngIf="valueGroupFormGroup.valueGroupRef | formatFieldUniqueId: control.valueRef as focusId">
  <div class="ytd-field">
    <div
      [class]="'field-focus large'"
      [libIsActiveField]="focusedField$ | async"
      [focusFieldUniqueId]="focusFieldUniqueId$ | async"
      [value]="control.valueRef"
      [focusId]="focusId"
      [ngClass]="{
        focused: isFocusable && isFocusEnabled,
      }"
      (mousedown)="selectFieldForFocus(focusId, control.valueRef, valueGroup)"
      data-testid="field-focus"
    >
      <lib-numeric-input
        [readonly]="true"
        [labelPosition]="'left'"
        [label]="'YTD ' + yearToDateCalculation"
        [control]="control"
        [sameSizeLabels]="true"
        [isCalculated]="true"
        [suffix]="unit"
        [forceAllowDecimals]="forceAllowDecimals"
        [maxDecimals]="maxDecimals"
      ></lib-numeric-input>
    </div>
  </div>
</ng-container>

<div
  class="periods-fields"
  [ngClass]="{
    'periods-fields-quarterly': frequency === 'quarterly',
    'periods-fields-monthly': frequency === 'monthly',
  }"
>
  <ng-container *ngFor="let freqControl of frequenciesControl">
    <div
      *ngIf="valueGroupFormGroup.valueGroupRef | formatFieldUniqueId: freqControl.valueRef as focusId"
      [class]="'field-focus large'"
      [libIsActiveField]="focusedField$ | async"
      [focusFieldUniqueId]="focusFieldUniqueId$ | async"
      [value]="freqControl.valueRef"
      [focusId]="focusId"
      [ngClass]="{
        focused: isFocusable && isFocusEnabled,
      }"
      (mousedown)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup)"
      data-testid="field-focus"
    >
      <lib-numeric-input
        [readonly]="!!freqControl.valueRef.is_read_only"
        [labelPosition]="'left'"
        [label]="freqControl.valueRef.fiscal_year_period! | frequencyFieldLabel: frequency"
        [matTooltip]="freqControl.valueRef.fiscal_year_period! | frequencyFieldPeriod"
        [control]="freqControl"
        [sameSizeLabels]="true"
        [isCalculated]="false"
        [suffix]="unit"
        [maxDecimals]="maxDecimals"
        [forceAllowDecimals]="forceAllowDecimals"
        [fiscalYearPeriod]="freqControl.valueRef.fiscal_year_period"
      ></lib-numeric-input>

      <div
        lib-field-comment-counter
        *ngIf="
          freqControl.valueRef.unresolved_regular_comments_and_replies ||
          !!freqControl.valueRef.unresolved_rejection_comments_and_replies
        "
        class="comments-count"
        [unresolved_regular_comments_and_replies]="freqControl.valueRef.unresolved_regular_comments_and_replies"
        [unresolved_rejection_comments_and_replies]="freqControl.valueRef.unresolved_rejection_comments_and_replies"
        (actionSelected)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup, $event)"
      ></div>
      <div
        lib-field-actions
        data-testid="fieldActions"
        *ngIf="displayFieldActions"
        [valueDefinitionType]="freqControl.valueRef.type"
        [unresolvedRejectionCommentsAndReplies]="freqControl.valueRef.unresolved_rejection_comments_and_replies"
        [valueGroupSetStatus]="valueGroupSetStatus"
        [dataRequestSourceStatus]="dataRequestSourceStatus"
        [collaboratorResponsibility]="collaboratorResponsibility"
        (actionSelected)="selectFieldForFocus(focusId, freqControl.valueRef, valueGroup, $event)"
      ></div>
    </div>
  </ng-container>
</div>
