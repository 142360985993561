<ng-template #defaultTemplate let-item="item">
  <button lib-button class="menu-item p-2 w-100" [buttonType]="'inherit'" (click)="action.emit(item)">
    <i *ngIf="item.icon" [class]="'pi pi-' + item.icon" class="mr-2" style="font-size: 1.4rem"></i>
    <span>{{ item.label }}</span>
  </button>
</ng-template>

<p-menu #actionMenu [model]="menuItems" [popup]="true" appendTo="body" [autoZIndex]="true" [baseZIndex]="800">
  <ng-template pTemplate="item" let-item>
    <ng-container *ngTemplateOutlet="template || defaultTemplate; context: { item }"></ng-container>
  </ng-template>
</p-menu>

<button
  type="button"
  pButton
  pRipple
  class="p-button-text p-button-rounded p-button-plain p-0"
  [disabled]="disabled"
  [icon]="'pi pi-' + icon"
  [iconPos]="'right'"
  [label]="label"
  (click)="actionMenu.toggle($event)"
  data-testid="moreActions"
></button>
