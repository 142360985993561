import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CardItem } from '../../../../models';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'lib-select-item-card',
  templateUrl: './select-item-card.component.html',
  styleUrls: ['./select-item-card.component.scss'],
})
export class SelectItemCardComponent<T extends CardItem = CardItem, I extends MenuItem = MenuItem> {
  @Input({ required: true }) item!: T;
  @Input() menuItems?: I[];
  @Input() disabled: boolean = false;
  @Input() selected: boolean = false;

  @ContentChild('extraContent', { static: false }) extraContentTmpl?: TemplateRef<unknown>;
  @Output() menuAction = new EventEmitter<{ action: I; item: T }>();
  @Output() selectChange = new EventEmitter<{ item: T; checked: boolean }>();
}
