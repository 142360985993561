import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CardItem } from '../../../../models';

@Component({
  selector: 'lib-sortable-card-list',
  templateUrl: './sortable-card-list.component.html',
  styleUrls: ['./sortable-card-list.component.scss'],
})
export class SortableCardListComponent<T extends CardItem = CardItem, I extends MenuItem = MenuItem> {
  @Input() items: T[] = [];
  @Input() menuItems: Record<string, I[]> = {};

  @Output() action = new EventEmitter<{ action: I; item: T }>();
  @Output() sortChange = new EventEmitter<{ item: T; index: number }>();
  @ContentChild('afterTitle', { static: false }) afterTitleTmpl?: TemplateRef<unknown>;

  private dragIndex?: number;

  public onDragEnter(index: number) {
    if (this.dragIndex != undefined && index !== this.dragIndex) {
      const items = this.items.slice();
      const [item] = items.splice(this.dragIndex, 1);
      items.splice(index, 0, item);
      this.items = items;
      this.dragIndex = index;
    }
  }

  public onDragStart(index: number) {
    this.dragIndex = index;
  }

  public onDrop(item: T, index: number) {
    this.sortChange.emit({ item, index });
    this.dragIndex = undefined;
  }
}
