import { Injectable } from '@angular/core';
import { Period } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class MetricEditorFrequencyService {
  numberOfMonthPerQuarter: number = 3;

  constructor() {}

  isPeriodEnabled(selectedPeriod: Period, valueFormControlPeriod: Period): boolean {
    const selectedPeriodIndex: number = this.periodIndex(selectedPeriod);
    const controlPeriodIndex: number = this.periodIndex(valueFormControlPeriod);

    if (this.isMonthlyPeriod(selectedPeriod)) {
      if (this.isMonthlyPeriod(valueFormControlPeriod)) {
        return selectedPeriodIndex == controlPeriodIndex;
      }
      if (this.isQuarterlyPeriod(valueFormControlPeriod)) {
        return selectedPeriodIndex / this.numberOfMonthPerQuarter == controlPeriodIndex;
      }
    }
    if (this.isQuarterlyPeriod(selectedPeriod)) {
      if (this.isMonthlyPeriod(valueFormControlPeriod)) {
        return Math.ceil(controlPeriodIndex / this.numberOfMonthPerQuarter) == selectedPeriodIndex;
      }
      if (this.isQuarterlyPeriod(valueFormControlPeriod)) {
        return selectedPeriod == valueFormControlPeriod;
      }
    }

    return true;
  }

  private isQuarterlyPeriod(period: Period): boolean {
    return period.charAt(0) === 'Q';
  }

  private isMonthlyPeriod(period: Period): boolean {
    return period.charAt(0) === 'M';
  }

  private periodIndex(period: Period): number {
    return Number(period.slice(1));
  }
}
