import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FocusedFieldAction } from '../../../../models';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'div[lib-field-comment-counter]',
  templateUrl: './field-comment-counter.component.html',
  styleUrls: ['./field-comment-counter.component.scss'],
})
export class FieldCommentCounterComponent {
  @Input() unresolved_regular_comments_and_replies?: number = 0;
  @Input() unresolved_rejection_comments_and_replies?: number = 0;

  @Output() actionSelected: EventEmitter<FocusedFieldAction> = new EventEmitter<FocusedFieldAction>();

  readonly eFocusedFieldAction = FocusedFieldAction;

  public handleFocusedFieldAction(clickEvent: MouseEvent, focusedFieldAction: FocusedFieldAction): void {
    clickEvent.stopPropagation();
    this.actionSelected.emit(focusedFieldAction);
  }
}
