import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

export interface CardListState {
  disabledMap: Record<string, boolean>;
  selectedMap: Record<string, boolean>;
}
export interface CardListView extends CardListState {
  count: number;
}

@Injectable()
export class CardListStore extends ComponentStore<CardListState> {
  public readonly selectedMap$: Observable<Record<string, boolean>> = this.select(
    (state: CardListState): Record<string, boolean> => state.selectedMap,
  );
  public readonly disabledMap$: Observable<Record<string, boolean>> = this.select(
    (state: CardListState): Record<string, boolean> => state.disabledMap,
  );

  public readonly count$: Observable<number> = this.select(
    this.selectedMap$,
    this.disabledMap$,
    (selectedMap: Record<string, boolean>, disabledMap: Record<string, boolean>): number =>
      Object.entries(selectedMap).reduce(
        (count: number, [id, selected]: [string, boolean]): number =>
          selected && !disabledMap[id] ? count + 1 : count,
        0,
      ),
  );
  public readonly cardListView$: Observable<CardListView> = this.select({
    count: this.count$,
    selectedMap: this.selectedMap$,
    disabledMap: this.disabledMap$,
  });

  constructor() {
    super({ selectedMap: {}, disabledMap: {} });
  }

  public readonly setSelectedMap = this.updater(
    (state: CardListState, selectedMap: Record<string, boolean>): CardListState => ({
      ...state,
      selectedMap,
    }),
  );
  public readonly setDisabledMap = this.updater(
    (state: CardListState, disabledMap: Record<string, boolean>): CardListState => ({
      ...state,
      disabledMap,
    }),
  );
  public readonly changeItemSelectedState = this.updater(
    (state: CardListState, value: Record<string, boolean>): CardListState => ({
      ...state,
      selectedMap: { ...state.selectedMap, ...value },
    }),
  );
}
