import { APIConfigAddon, SupportedEnvironment } from '../app/shared/models';

export const sharedApiConfig: APIConfigAddon = {
  minivisto: false,
  minivistoPort: '9080',
  pageSize: 24,
  env: SupportedEnvironment.localhost,
  clientId: 'ce10a929-ab6b-4ab0-a82d-0c7d1544b7b8',
  scope:
    'openid+profile+email+email+offline_access+tags:view+indicators:view+core_data:view+metrics:view+fiscal_years:view+core_metrics:view+business_units:view+documents:view+option_lists:view',
  apis: {
    auth: {
      api: '/auth',
      localPort: '8080',
      resources: {
        auth_tokens: '/oauth2/token',
        oauth: '/oauth2/auth',
      },
    },
    external: {
      api: '',
      localPort: '8091',
      resources: {
        clientConfigs: '/client_configs/v1',
        documents: '/documents/v1',
        indicators: '/indicators/v1',
        tags: '/tags/v1',
        fiscalYears: '/fiscal_years/v1',
        topics: '/topics/v1',
        metricGroups: '/metric_groups/v1',
        optionLists: '/option_lists/v1',
        standardCodes: '/standard_codes/v1',
        source: '/business_units/v1',
        frameworks: '/frameworks/v1',
        taxonomies: '/taxonomies/v1',
        users: '/users/v1',
        units: '/units/v1',
        featureFlags: '/feature_flags/v1',
      },
    },
  },
  components: {
    editor: {
      toolbar: {
        items: ['Bold', 'Italic', 'UnorderedList', 'OrderedList', 'CreateLink', '|', 'FullScreen'],
        enableFloating: false,
      },
      pasteCleanupSettings: {
        prompt: false,
        plainText: false,
        keepFormat: true,
        deniedTags: [
          'abbr',
          'acronym',
          'address',
          'applet',
          'area',
          'article',
          'aside',
          'audio',
          'base',
          'basefont',
          'bdi',
          'bdo',
          'big',
          'blockquote',
          'body',
          'button',
          'canvas',
          'caption',
          'center',
          'code',
          'col',
          'colup',
          'data',
          'datalist',
          'dd',
          'del',
          'details',
          'dfn',
          'dialog',
          'dir',
          'dl',
          'dt',
          'embed',
          'fieldset',
          'figcaption',
          'figure',
          'font',
          'footer',
          'form',
          'frame',
          'frameset',
          'head',
          'header',
          'html',
          'iframe',
          'img',
          'input',
          'ins',
          'kbd',
          'label',
          'legend',
          'link',
          'main',
          'map',
          'mark',
          'meta',
          'meter',
          'nav',
          'noframes',
          'noscript',
          'option',
          'output',
          'param',
          'picture',
          'pre',
          'progress',
          'q',
          'rp',
          'rt',
          'ruby',
          's',
          'samp',
          'script',
          'section',
          'select',
          'source',
          'strike',
          'style',
          'sub',
          'summary',
          'sup',
          'svg',
          'table',
          'tbody',
          'td',
          'template',
          'textarea',
          'tfoot',
          'th',
          'thead',
          'time',
          'title',
          'tr',
          'track',
          'tt',
          'u',
          'var',
          'video',
          'wbr',
        ],
      },
    },
  },
};
