<ng-container *ngIf="dynamicFieldForm$ | async as dynamicFieldForm">
  <lib-select-input
    class="formfield fx-grow required pt-1 pb-2"
    [label]="'Option list display type' | translate"
    [hint]="'Pick one' | translate"
    [control]="dynamicFieldForm.propertiesControls.widgetType"
    [options]="widgetTypes"
    [messages]="{ invalidWidgetType: 'Invalid type change for a published choice field' | translate }"
    (selectionChangeEvent)="widgetTypeChanged()"
  >
  </lib-select-input>

  <lib-slide-toggle
    *ngIf="
      dynamicFieldForm.propertiesControls.widgetType?.value === eChoiceFieldWidgetType.single_select ||
      dynamicFieldForm.propertiesControls.widgetType?.value === eChoiceFieldWidgetType.multi_chip
    "
    class="formfield"
    [label]="'Allows users to manually enter options' | translate"
    [control]="dynamicFieldForm.propertiesControls.allowAddOption"
  >
  </lib-slide-toggle>

  <div
    class="formfield large pt-1 pb-2"
    *ngIf="dynamicFieldForm.propertiesControls.choiceList && dynamicFieldForm.propertiesControls.choiceListCategory"
  >
    <lib-metric-structure-choice-field-selection
      [optionListFormControl]="dynamicFieldForm.propertiesControls.optionList"
      [selectionListApplyAllFormControl]="dynamicFieldForm.propertiesControls.choiceListApplyAll"
      [selectionListFormControl]="dynamicFieldForm.propertiesControls.choiceList"
      [selectionListCategoryFormControl]="dynamicFieldForm.propertiesControls.choiceListCategory"
      [metric]="metric"
      [valueDefinition]="valueDefinition"
      [canCreateCustomChoices]="!!(canCreateCustomChoices$ | async)"
      (customChoiceAnswersChange)="updateCustomChoiceAnswers($event)"
    >
    </lib-metric-structure-choice-field-selection>
  </div>

  <ng-container *ngIf="dynamicFieldForm.propertiesControls.choiceListApplyAll?.value">
    <lib-slide-toggle
      [label]="'Include an Explanation Text Field' | translate"
      [control]="dynamicFieldForm.propertiesControls.displayExplanation"
      (sliderToggle)="toggleDisplay($event)"
    >
    </lib-slide-toggle>

    <div
      *ngIf="
        dynamicFieldForm.propertiesControls.displayExplanation?.value &&
        dynamicFieldForm.propertiesControls.explanationLabel
      "
      class="large required ml-8 pt-1 pb-4"
    >
      <lib-slide-toggle
        class="formfield pt-1 pb-2"
        [label]="'Explanation Text Field is required' | translate"
        [control]="dynamicFieldForm.propertiesControls.explanationRequired"
      >
      </lib-slide-toggle>
      <lib-text-input
        [label]="'Explanation Text Field label' | translate"
        [hint]="'This will appear as a textbox with a 5000 characters limit' | translate"
        [control]="dynamicFieldForm.propertiesControls.explanationLabel"
        class="formfield pt-1 pb-2"
      ></lib-text-input>
    </div>
  </ng-container>
</ng-container>
