export enum Frequency {
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
}

export enum YearToDateCalculation {
  LATEST = 'latest',
  SUM = 'sum',
  AVERAGE = 'average',
}

export interface ValueDefinitionFrequency {
  id: string;
  fiscal_year_id: string;
  value_definition_id: string;
  year_to_date_calculation: YearToDateCalculation | null;
  frequency?: Frequency;
}

export interface ValueDefinitionFrequencyStructure extends ValueDefinitionFrequency {
  has_values?: boolean;
}

export interface UpdatedValueDefinitionFrequencyRequest {
  frequency_code: string;
  year_to_date_calculation: YearToDateCalculation | null;
  frequency?: Frequency;
  value_definition_id: string;
}

export interface BulkUpdateValueDefinitionFrequencyRequest {
  value_definition_frequencies: UpdatedValueDefinitionFrequencyRequest[];
}

export enum YearToDateCalculationLabel {
  latest = 'Latest',
  sum = 'SUM',
  average = 'Average',
}
