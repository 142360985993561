import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { CardItem } from '../../../../models';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { CardListStore, CardListView } from './../card-list.store';
import { provideComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-select-card-list',
  templateUrl: './select-card-list.component.html',
  styleUrls: ['./select-card-list.component.scss'],
  providers: [provideComponentStore(CardListStore)],
})
export class SelectCardListComponent<T extends CardItem = CardItem, I extends MenuItem = MenuItem> {
  @Input() isLoading: boolean = false;
  @Input() reachedEnd: boolean = false;
  @Input() items: T[] = [];

  @Input() set selectedIds(value: string[]) {
    const selectedMap = value.reduce((acc, id) => ({ ...acc, [id]: true }), {});
    this.cardListStore.setSelectedMap(selectedMap);
  }
  @Input() set disabledIds(value: string[]) {
    const disabledMap = value.reduce((acc, id) => ({ ...acc, [id]: true }), {});
    this.cardListStore.setDisabledMap(disabledMap);
  }
  @Input() menuItems: Record<string, I[]> = {};
  @Input() markDisabledSelected = false;

  @Output() action = new EventEmitter<{ action: I; item: T }>();
  @Output() loadMore = new EventEmitter<void>();
  @Output() selectionChange = new EventEmitter<{ id: string; checked: boolean }>();
  @ContentChild('extraContent', { static: false }) extraContentTmpl?: TemplateRef<unknown>;

  cardListView$: Observable<CardListView> = this.cardListStore.cardListView$;

  constructor(private readonly cardListStore: CardListStore) {}

  public onLoadMore(): void {
    if (!this.isLoading && !this.reachedEnd) {
      this.loadMore.emit();
    }
  }

  public onSelectChange(item: T, event: CheckboxChangeEvent): void {
    this.cardListStore.changeItemSelectedState({ [item.id]: event.checked });
    this.selectionChange.emit({ id: item.id, checked: event.checked });
  }

  trackById(_index: number, item: T): string {
    return item.id;
  }
}
