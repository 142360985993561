import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import {
  DeactivateEntityTypes,
  Metric,
  MetricTableDefinition,
  ValueDefinition,
  ValueDefinitionType,
} from '../../../../models';
import { MetricTableGroup } from '../../../models';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { finalize } from 'rxjs/operators';
import { MetricApiService } from '../../../../services/types';
import { DeactivateEntityService } from '../../../services/deactivate-entity/deactivate-entity.service';
import { ActivateEntityService } from '../../../services/activate-entity/activate-entity.service';
import { MetricUtils } from '../../../../classes';
import { FeatureFlagService } from '../../../../feature-flag';
import { BaseMetricStructureService } from '../../../services/base-metric-structure/base-metric-structure.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lib-metric-structure-table',
  templateUrl: './metric-structure-table.component.html',
  styleUrls: ['./metric-structure-table.component.scss'],
})
export class MetricStructureTableComponent implements OnChanges, OnInit, OnDestroy {
  @Input({ required: true }) metricTableGroup!: MetricTableGroup;
  @Input({ required: true }) index!: number;
  @Input({ required: true }) metric!: Metric;
  @Input() canMoveUp: boolean = false;
  @Input() canMoveDown: boolean = false;
  @Input() overlayDisabled: boolean = false;
  @Input() isActive: boolean = false;
  @Input() indexOffSet: number = 0;

  @ViewChild('metricTable') metricTable?: ElementRef<HTMLDivElement>;

  readonly eValueDefinitionType = ValueDefinitionType;

  tableTotals: ValueDefinition[] = [];

  isRefV2MetricInPlatform: boolean = false;
  isAdmin: boolean = false;
  public deactivationEnabled: boolean = false;
  public metricTableDefinition?: MetricTableDefinition;
  public metricTablePublishedSubscription?: Subscription;

  constructor(
    private metricStructureService: MetricStructureStateService,
    private metricsService: MetricApiService,
    private deactivateEntityService: DeactivateEntityService,
    private activateEntityService: ActivateEntityService,
    private featureFlagService: FeatureFlagService,
    private baseMetricStructureService: BaseMetricStructureService,
  ) {}

  ngOnInit() {
    this.isAdmin = this.metricStructureService.isAdmin;
    this.isRefV2MetricInPlatform = MetricUtils.isRefV2Metric(this.metric) && !this.isAdmin;
    this.deactivationEnabled = this.featureFlagService.areAnyFeatureFlagsEnabled([
      'metric_structure_deactivation_enabled',
    ]);

    if (this.isAdmin && this.metricTableGroup.table_id) {
      this.metricStructureService
        .getMetricTableDefinition(this.metric.id, this.metricTableGroup.table_id)
        .subscribe((metricTableDefinition) => {
          this.metricTableDefinition = metricTableDefinition;
        });

      this.metricTablePublishedSubscription = this.baseMetricStructureService.metricTablePublished$.subscribe(
        (metricTableId: string | null) => {
          if (this.metricTableDefinition && (!metricTableId || this.metricTableDefinition?.id === metricTableId)) {
            this.metricTableDefinition.published = Date.now().toString();
          }
        },
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.configureTableTotal();
    if (this.isActive && Boolean(changes.metric)) {
      setTimeout(() => {
        this.metricTable?.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);
    }
  }

  public ngOnDestroy(): void {
    this.metricTablePublishedSubscription?.unsubscribe();
  }

  configureTableTotal() {
    this.tableTotals = this.metricTableGroup.valueDefinitionGroups
      .filter((vdg) => vdg.value_definitions)
      .flatMap((vdg) =>
        (vdg.value_definitions as ValueDefinition[]).filter((vd) => vd.type === ValueDefinitionType.calculated),
      )
      .sort((a, b) => (a.position && b.position ? a.position - b.position : 0));
  }

  public setSelectedItem(item?: MetricTableGroup): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  public moveTableDown(event: MouseEvent): void {
    this.moveTable(event, this.canMoveDown, 1);
  }

  public moveTableUp(event: MouseEvent): void {
    this.moveTable(event, this.canMoveUp, -1);
  }

  public deleteTable(event: MouseEvent): void {
    event.stopPropagation();
    this.metricStructureService.deleteMetricTable(this.metricTableGroup.metric_id, this.metricTableGroup.id);
  }

  private moveTable(event: MouseEvent, canMoveCondition: boolean, newPositionOffset: number): void {
    const currentDisplayPosition = this.index + 1 + this.indexOffSet;
    event.stopPropagation();
    this.metricStructureService.updateIsMetricUpdating(true);
    if (canMoveCondition) {
      this.metricsService
        .moveMetricTable(
          this.metricTableGroup.metric_id,
          this.metricTableGroup.id,
          currentDisplayPosition + newPositionOffset,
        )
        .pipe(finalize(() => this.metricStructureService.updateIsMetricUpdating(false)))
        .subscribe((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.setSelectedItem(this.metricTableGroup);
        });
    }
  }

  public deactivateMetricTableGroup(): void {
    this.deactivateEntityService.deactivate(
      DeactivateEntityTypes.TABLE,
      this.metricTableGroup.metric_id,
      undefined,
      undefined,
      this.metricTableGroup,
    );
  }

  public activateMetricTableGroup(): void {
    this.activateEntityService.activateMetricTable(this.metricTableGroup);
  }
}
