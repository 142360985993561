import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { MetricTableGroup } from '../../../models';
import { Metric, MetricTableDefinition } from '../../../../models';
import { MetricStructureUtils } from '../../../classes/MetricStructureUtils/metric-structure-utils';

type FormGroupMdel = { [key: string]: AbstractControl };
interface MetricTableFormModel extends FormGroupMdel {
  tableTitleControl: FormControl<string>;
  tableToggleActiveControl: FormControl<boolean>;
}

export const METRIC_TABLE_TITLE_MAX_LENGTH: number = 220;

export class MetricTableForm extends FormGroup<MetricTableFormModel> {
  constructor(
    metricTableGroup: MetricTableGroup | MetricTableDefinition,
    metric: Metric,
    isCore: boolean,
    isMetricTableDeactivationEnabled: boolean,
    readonly fb = new FormBuilder(),
  ) {
    super({
      tableTitleControl: fb.control(metricTableGroup?.title ?? '', {
        nonNullable: true,
        validators: [Validators.maxLength(METRIC_TABLE_TITLE_MAX_LENGTH), Validators.required],
      }),
      tableToggleActiveControl: fb.control(false, { nonNullable: true }),
    });

    if (
      isMetricTableDeactivationEnabled &&
      MetricStructureUtils.isCoreTableOfRefV2OrTPMInPlatform(metric, metricTableGroup, isCore) &&
      this.controls.tableTitleControl.value
    ) {
      this.controls.tableTitleControl.disable();
    }
  }
}
