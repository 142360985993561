import { CreatedUpdatedEntity, Entity, Tag, User } from '../entities';
import { DataRequestSource, DataRequestSourceStatus, DataRequestUserResponsibility } from './sources';
import { Indicator, ValueGroupSet } from '../metrics';
import { PaginationData } from '../pagination';
import { Frequency } from '../valueDefinitionFrequencies';
import { TagType } from '../components';
import { FiscalYearPeriod } from '../fiscal-years';

export type CreationDataRequest = Omit<DataRequest, 'id' | 'indicator_links' | 'status' | 'permanently_deactivated'> & {
  metric_group_id?: string;
};

export type UpdateDataRequestPayload = {
  name?: string;
  introduction?: string;
  conclusion?: string;
  frequency_code?: string;
  prefill_frequency_code?: string | null;
  show_historical_data_to?: DataRequestToolPanelVisibilityType;
  show_recommendations_to?: DataRequestToolPanelVisibilityType;
  show_audit_trail_panel_to?: DataRequestToolPanelVisibilityType;
  show_consolidation_panel_to?: DataRequestToolPanelVisibilityType;
  allow_reviewers_to_edit?: boolean;
  allow_approvers_to_edit?: boolean;
  allow_users_to_attach_public_files?: DataRequestToolPanelVisibilityType;
  allow_users_to_attach_private_files?: DataRequestToolPanelVisibilityType;
  hide_metric_codes?: boolean;
  tags?: string[];
};

export type UpdateDataRequestDueDateNotificationPayload = {
  due_date?: string | null;
  due_date_notifications?: DueDateNotification[];
};

export type UpdateDataRequestIndicatorLink = Partial<
  Pick<DataRequestIndicatorLink, 'position' | 'instructions' | 'important'>
>;

export type DataRequestUserPayload = {
  user_ids: string[];
  responsibility: DataRequestUserResponsibility;
  notify_user: boolean;
};

export type UpdateDataRequestUserPayload = Omit<DataRequestUserPayload, 'user_ids'>;

export type CreatePublicUserPayload = Pick<User, 'email' | 'first_name' | 'last_name'>;

export interface CreateDataRequestUser {
  dataRequestUserPayload: DataRequestUserPayload;
  createPublicUserPayload?: CreatePublicUserPayload;
}

export interface CopyDataRequestPayload {
  name: string;
  frequency_code: string;
  due_date?: string;
  frequency?: Frequency;
  copy_structure: boolean;
  copy_sources: boolean;
  copy_participants: boolean;
  copy_due_date_reminders: boolean;
}

export interface DataRequestIndicatorLink extends Entity {
  position: number;
  instructions?: string;
  important: boolean;
  indicator: DataRequestIndicator;
  data_request_id: string;
  deactivated: boolean;
}

export interface DataRequestIndicatorLinkWithoutIndicator extends Entity {
  position: number;
  instructions?: string;
  important: boolean;
  data_request_id: string;
  deactivated: boolean;
}

export interface DataRequestIndicator extends Indicator {
  value_group_sets: DataRequestValueGroupSet[];
}

export interface DataRequestRow {
  id: string;
  status: string;
  name: string;
  fiscal_year: string;
  creation_date: string;
  due_date: DueDateCell;
  action: DataRequest;
  isExpanded: boolean;
  questionnaire?: QuestionnaireCell;
  period?: string;
  frequency: Frequency;
  permanently_deactivated: boolean;
  previous_data_request_status?: DataRequestStatus;
}

export interface DataRequestRowV2 {
  id: string;
  status: string;
  name: string;
  fiscal_year: string;
  creation_date: string;
  due_date: DueDateCell;
  action: DataRequest;
  questionnaire?: QuestionnaireCell;
  period?: string;
  frequency: Frequency;
  permanently_deactivated: boolean;
}

export interface GroupStatusInfo {
  status: DataRequestGroupStatus;
  tagType: TagType;
}

export enum DataRequestGroupStatus {
  NOT_STARTED = 'Not started',
  IN_PROGRESS = 'In progress',
  IMPORTED = 'Imported',
  INACTIVE = 'Inactive',
  UNKNOWN = 'Unknown',
}

export interface DueDateCell {
  dueDate: string;
  dataRequestStatus?: string;
}

export interface QuestionnaireCell {
  questionnaireId: string;
  questionnaireName: string;
}

export interface DataRequestValueGroupSet extends ValueGroupSet {
  status: DataRequestValueGroupSetStatus;
  transitions: DataRequestTransition[];
  notes: DataRequestNote[];
}

export enum DataRequestStatus {
  DRAFT = 'draft',
  INACTIVE = 'inactive',
  ACTIVE = 'active',
  IMPORTED = 'imported',
  ARCHIVED = 'archived',
}

export enum DataRequestMarkAsStatus {
  MARK_AS_COMPLETED = 'markAsComplete',
  MARK_AS_IN_PROGRESS = 'markAsInProgress',
  ACCEPT = 'accept',
  REVERSE_ACCEPTANCE = 'reverseAcceptance',
  APPROVE = 'approve',
  REVERSE_APPROVAL = 'reverseApproval',
  REJECT = 'reject',
  REVERSE_REJECTION = 'reverseRejection',
}

export enum DataRequestValueGroupSetStatus {
  COMPLETED = 'completed',
  NOT_STARTED = 'not_started',
  IN_PROGRESS = 'in_progress',
  ACCEPTED = 'accepted',
  APPROVED = 'approved',
  VALIDATE = 'validate',
  REJECTED = 'rejected',
}

export enum DataRequestType {
  REGULAR = 'regular',
  QUESTIONNAIRE = 'questionnaire',
}

export interface MinimalDataRequest extends CreatedUpdatedEntity {
  id: string;
  name: string;
  source_statuses: DataRequestSourceStatus[];
}

export enum DueDateNotificationTimeframeType {
  CALENDAR_DAYS = 'calendar_days',
  WEEKS = 'weeks',
}

export enum DueDateNotificationRelativeToType {
  BEFORE = 'before',
  AFTER = 'after',
  ON_DUE_DATE = 'on_due_date',
}

export enum DueDateNotificationScheduleType {
  ONE_WEEK_BEFORE = 'one_week_before',
  ONE_DAY_BEFORE = 'one_day_before',
  ON_DUE_DATE = 'on_due_date',
  ONE_DAY_AFTER = 'one_day_after',
  EVERY_DAY_PAST_DUE_DATE = 'every_day_past_due_date',
  CUSTOM = 'custom',
}

export interface DueDateNotificationSchedule {
  frequency: number;
  timeframe: DueDateNotificationTimeframeType;
  relative_to: DueDateNotificationRelativeToType;
}

export interface DueDateNotification {
  source_status?: DataRequestSourceStatus;
  participants?: DataRequestUserResponsibility[];
  schedule: DueDateNotificationSchedule;
}

export interface DataRequest extends Entity {
  status: DataRequestStatus;
  frequency: Frequency;
  frequency_code: string;
  indicator_links: DataRequestIndicatorLink[];
  type: DataRequestType;
  fiscal_year_period_id?: string;
  fiscal_year_period?: FiscalYearPeriod;
  introduction?: string;
  conclusion?: string;
  prefill_frequency_code?: string;
  data_request_template_id?: string;
  show_historical_data_to?: DataRequestToolPanelVisibilityType;
  show_recommendations_to?: DataRequestToolPanelVisibilityType;
  show_audit_trail_panel_to?: DataRequestToolPanelVisibilityType;
  show_consolidation_panel_to?: DataRequestToolPanelVisibilityType;
  allow_reviewers_to_edit?: boolean;
  allow_approvers_to_edit?: boolean;
  allow_users_to_attach_public_files: DataRequestToolPanelVisibilityType;
  allow_users_to_attach_private_files: DataRequestToolPanelVisibilityType;
  tags?: Tag[];
  sources?: DataRequestSource[];
  due_date?: string;
  report_id?: string;
  hide_metric_codes?: boolean;
  due_date_notifications?: DueDateNotification[];
  data_request_frequency_group_id?: string;
  previous_data_request_status?: DataRequestStatus;
  permanently_deactivated: boolean;
}

export enum DataRequestToolPanelVisibilityType {
  INTERNAL_ONLY = 'internal_only',
  ALL = 'all',
  NONE = 'none',
}

export interface DataRequestNote {
  id: string;
  title: string;
  note?: string;
  parent_data_request_note_id?: string;
  data_request_id: string;
  note_author: string;
  children?: DataRequestNote[];
  created: Date;
  created_by: string;
}

export interface DataRequestTransition {
  id: string;
  from_status: DataRequestValueGroupSetStatus | DataRequestSourceStatus;
  to_status: DataRequestValueGroupSetStatus | DataRequestSourceStatus;
  value_group_set_id: string;
  data_request_note_id: string;
  responsibility: DataRequestUserResponsibility;
  created: Date;
  note?: DataRequestNote;
  sign_off_message?: string;
}

export interface DataRequestNoteAggregate extends DataRequestTransition {
  to_status_text: string;
  replies_expanded: boolean;
}

export interface DataRequestFilteringData {
  business_unit_id?: string;
  frequency_code?: string;
  frequency_period?: string;
  status?: DataRequestStatus[] | null;
  tag?: string;
  indicator_id?: string;
}

export type DataRequestOrderByType = 'name' | 'updated' | 'due_date';
export interface DataRequestOrderingData {
  order_by?: DataRequestOrderByType;
  order_by_direction?: 'asc' | 'desc';
}
export interface DataRequestSearchData {
  query?: string;
}

export interface DataRequestFilteringOptions {
  search?: DataRequestSearchData;
  filters?: DataRequestFilteringData;
  ordering?: DataRequestOrderingData;
  pagination?: PaginationData;
}

export interface DataRequestWorkflowAutomation {
  data: any;
  type: DataRequestWorkflowAutomationType;
}

export enum DataRequestWorkflowAutomationType {
  NAVIGATION = 'navigation',
  SUBMIT_ACTION = 'submitAction',
  NONE = 'none',
}

export enum TransitionType {
  SOURCE_STATUS_TRANSITION = 'source_status_transition',
  METRIC_STATUS_TRANSITION = 'metric_status_transition',
}

export enum DataRequestFieldCommentType {
  REGULAR = 'regular',
  FIELD_REJECT = 'field_reject',
}

export interface BaseDataRequestFieldComment {
  id: string;
  comment: string;
  data_request_source_status: DataRequestSourceStatus;
  author: string;
  created: Date;
}

export interface DataRequestFieldComment extends BaseDataRequestFieldComment {
  comment_type: DataRequestFieldCommentType;
  resolved: boolean;
  value_definition_id: string;
  data_request_value_group_set_id: string;
  data_request_value_group_id: string;
  latest_reply: DataRequestFieldCommentReply | null;
  reply_count: number;
}

export interface DataRequestFieldCommentReply extends BaseDataRequestFieldComment {
  data_request_field_comment_id: string;
}

export interface CheckRequestHasRejectedValue {
  has_rejected_value: boolean;
}

export interface FieldRejectionCommentsCount {
  rejection_comments_in_contribution: number;
  rejection_comments_in_review: number;
  rejection_comments_in_approval: number;
}

export interface UserActiveRequestsCount {
  user_active_request_count: number;
}
