<ng-container *libFeatureFlag="{ featureFlags: ['new_rich_text_editor_enabled'] }; else legacyRichText">
  <lib-rich-text-editor-input
    [label]="label"
    [control]="control"
    [messages]="messages"
    [hint]="hint"
    [placeholder]="placeholder"
    [autofocus]="autofocus"
    [size]="size"
    [autoSaveInterval]="autoSaveInterval"
    (blur)="blur.emit()"
  >
  </lib-rich-text-editor-input>
</ng-container>

<ng-template #legacyRichText>
  <lib-rich-text-legacy-input
    [label]="label"
    [control]="control"
    [messages]="messages"
    [hint]="hint"
    [placeholder]="placeholder"
    [autofocus]="autofocus"
    [size]="size"
    [autoSaveInterval]="autoSaveInterval"
    [enableSourceCodeOption]="enableSourceCodeOption"
    (blur)="blur.emit()"
  >
  </lib-rich-text-legacy-input>
</ng-template>
