<p-fileUpload
  mode="advanced"
  [url]="url"
  [chooseIcon]="chooseIcon"
  [method]="method"
  [accept]="accept"
  [chooseLabel]="label"
  [disabled]="disabled"
  [auto]="autoUpload"
  [maxFileSize]="maxFileSize"
  [showUploadButton]="showUploadButton"
  [showCancelButton]="showCancelButton"
  [fileLimit]="maxFiles"
  [multiple]="multiple"
  [customUpload]="true"
  (uploadHandler)="uploadHandler.emit($event)"
>
</p-fileUpload>
