import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { CardItem } from '../../../../models';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'lib-drag-item-card',
  templateUrl: './drag-item-card.component.html',
  styleUrls: ['./drag-item-card.component.scss'],
})
export class DragItemCardComponent<T extends CardItem = CardItem, I extends MenuItem = MenuItem> {
  @Input({ required: true }) item!: T;
  @Input() menuItems?: I[];
  @Input() disabled: boolean = false;

  @Output() menuAction = new EventEmitter<{ action: I; item: T }>();
  @ContentChild('afterTitle', { static: false }) afterTitleTmpl?: TemplateRef<unknown>;
}
