import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ValueDefinitionSize } from '../../../models';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { RichTextLegacyInputComponent } from '../rich-text-legacy-input/rich-text-legacy-input.component';

@Component({
  selector: 'lib-rich-text-input',
  templateUrl: './rich-text-input.component.html',
  styleUrls: ['./rich-text-input.component.scss'],
})
export class RichTextInputComponent {
  @Input() label: string = '';
  @Input() control?: UntypedFormControl;
  @Input() messages?: ValidationErrors;
  @Input() hint?: string;
  @Input() placeholder: string = '';
  @Input() autofocus: boolean = false;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() enableSourceCodeOption: boolean = false;
  @Input() autoSaveInterval?: number;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<void>();

  @ViewChild('richTextEditorComponent')
  public richTextEditorComponent?: RichTextLegacyInputComponent;

  public setFocus(): void {
    this.richTextEditorComponent?.setFocus();
  }
}
