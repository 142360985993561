import { Doc, MetricCategory, MetricValueDefinitionAttribute } from './entities';
import { BaseValue } from './values';
import { DataRequestStatus } from './data-requests';
import { TemplateRef } from '@angular/core';
import { ConditionalTrigger, Indicator, ValueDefinition, ValueDefinitionGroup } from './metrics';
import { Icon } from '../icons/icons.model';
import { Permission } from './configs';
import { FeatureFlagKeys } from '../feature-flag';

export {
  // Actions
  ActionItem,
  PageActions,
  BtnType,
  BtnTypeCssClass,
  // Results
  Collection,
  Status,
  // Search
  Layout,
  MetricsEditorToolsEnum,
  Presentation,
  ItemType,
  RelatedMetricsResourceType,
  NavigationDirection,
  ResourceType,
  SearchOptions,
  SearchOptionFilters,
  SearchOptionMultiSelectFilters,
  DefaultSearchOptions,
  SearchFilterConfig,
  CustomSearchPropertiesType,
  SupportedEnvironment,
  EsSearchOptionsPayload,
  SearchBarFilterResourceArgs,
  SearchBarFilterResourceType,
  SearchOptionsPayload,
  Filter,
  SortFilter,
  MoveDirection,
  ToolsTabType,
  ViewerActionType,
  DataRequestStatusIcon,
  SourceType,
  TableColumn,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-redundant-type-constituents
interface ActionItem<T = any | void> {
  id: string; // used for navigation
  title: string; // display name
  subtitle?: string; //
  text?: string; //
  icon?: Icon;
  image?: string; //
  action?: string; // for action different than navigation
  msg_count?: number; // number of alerts
  disabled?: boolean; //
  deactivated?: boolean; // user interaction activation
  created?: Date | string; //
  start?: Date;
  updated?: Date | string;
  updated_by?: string; //
  children?: ActionItem<T>[]; // used for trees and menus
  item_type?: string; // used to determine the APIs to use
  item?: T; // original entity
  viewerAction?: ViewerActionType;
  featureFlag?: FeatureFlagKeys | FeatureFlagKeys[];
  featureFlagHiddenWhenEnabled?: boolean;
  permissions?: Permission[];
  queryParams?: { action: string };
  selected?: boolean;
  levelClass?: string;
  isLabel?: boolean; // user interaction activation
}

interface PageActions<T = void> {
  main_actions?: ActionItem<T>[];
  action_list?: ActionItem<T>[];
  buttons?: ActionItem<T>[];
  drop_down?: ActionItem<T>;
  disabled?: boolean;
}

interface Collection<T = void> {
  count: number;
  items: ActionItem<T>[];
}

interface TableColumn<T> {
  name: string;
  dataKey: keyof T | string;
  key?: keyof T | string;
  position?: 'right' | 'left';
  isSortable?: boolean;
  cellTemplate?: TemplateRef<any>;
  headerTemplate?: TemplateRef<any>;
  maxCharCount?: number;
  width?: string;
  noHeader?: boolean;
}

enum Layout {
  list = 'list',
  grid = 'grid',
}

enum Presentation {
  card = 'card',
  doc = 'doc',
  highlights = 'highlights',
  table = 'table',
  doclink = 'doclink',
  standardCodes = 'standard_codes',
  actions = 'action',
  matTable = 'matTable',
  cardLink = 'cardLink',
  page = 'page',
  form = 'form',
}

enum ItemType {
  docs_doc = 'docs_doc',
  docs_report = 'docs_report',
  docs_resource = 'docs_resource',
  library_peers_report = 'library_peers_report',
  library_peers_peer = 'library_peers_peer',
  metrics_indicator = 'metrics_indicator',
  metrics_metric = 'metrics_metric',
  metrics_template = 'metrics_template',
  metrics_code = 'metrics_code',
  metrics_preview = 'metrics_preview',
  sources_peer = 'sources_peer',
  benchmark_v2_exports = 'benchmark_v2_exports',
  source_comparison_v1_exports = 'source_comparison_v1_exports',
  data_table_v2_exports = 'data_table_v2_exports',
  report_exports = 'report_exports',
  reports_report = 'reports_report',
  reports_template = 'reports_template',
  reports_indicators = 'reports_indicators',
  benchmarks_benchmark = 'benchmarks_benchmark',
  analyze_dataset = 'analyze_dataset',
  selection_set = 'selection_set',
  selection_set_item = 'selection_set_item',
  peers_peer = 'peers_peer',
  unit_group = 'unit_group',
  unit_group_item = 'unit_group_item',
  standard_codes = 'standard_codes',
  admin_standard_codes = 'admin_standard_codes',
  framework_metric = 'framework_metric',
  peer_metric = 'peer_metric',
  users_user = 'users_user',
  tags_tag = 'tags_tag',
  data_requests_request = 'data_requests_request',
  public_data_requests_request = 'public_data_requests_request',
  sources_source = 'sources_source',
  data_requests_source = 'data_requests_source',
  data_request_indicator_link = 'data_request_indicator_link',
  data_requests_user = 'data_requests_user',
  metric_data_exports = 'metric_data_exports',
  admin_templates_metrics = 'admin_templates_metrics',
  metrics_framework = 'metrics_framework',
  standard_codes_framework = 'standard_codes_framework',
  core_orgs = 'core_orgs',
  related_metrics = 'related_metrics',
  integration_settings = 'integration_settings',
}

interface SearchBarFilterResourceArg<T> {
  excluded?: Array<T>;
}

interface SearchBarFilterResourceArgs {
  [SearchBarFilterResourceType.category]?: SearchBarFilterResourceArg<MetricCategory>;
}

enum SearchBarFilterResourceType {
  year = 'year',
  source = 'source',
  topic = 'topic',
  category = 'category',
  type = 'type',
  framework = 'framework',
  industry = 'industry',
  tag = 'tag',
  status = 'status',
  author = 'author',
  standard_codes = 'standard_codes',
  role = 'role',
  questionnaire_framework = 'questionnaire_framework',
  start = 'start',
  admin_standard_codes = 'admin_standard_codes',
  taxonomy = 'taxonomy',
  sort = 'sort',
}

enum ResourceType {
  year = 'year',
  year_public = 'year_public',
  year_with_id = 'year_with_id',
  source = 'source',
  topic = 'topic',
  category = 'category',
  type = 'type',
  framework = 'framework',
  standard_codes = 'standard_codes',
  industry = 'industry',
  tag = 'tag',
  status = 'status',
  author = 'author',
  role = 'role',
  min_metrics = 'min_metrics',
  user = 'user',
  template = 'template',
  org = 'org',
  unit = 'unit',
  sort = 'sort',
  selection_set = 'selection_set',
  selection_set_item = 'selection_set_item',
  selection_set_item_public = 'selection_set_item_public',
  metric_group = 'metric_group',
  questionnaire_framework = 'questionnaire_framework',
  metrics_indicator = 'metrics_indicator',
  taxonomy = 'taxonomy',
  taxonomy_code = 'taxonomy_code',
  country = 'country',
  cdp_sector = 'cdp_sector',
  wdi_industry = 'wdi_industry',
  option_list_category = 'option_list_category',
  docs_doc = 'docs_doc',
  metric_data_exports = 'metric_data_exports',
  option_list_item = 'option_list_item',
}

interface Filter {
  field: string;
  value: any;
  type?: string;
}

interface EsSearchOptionsPayload {
  size: number;
  from: number;
  keywords?: string;
  search_type?: string;
  filters: Filter[];
  sort?: any[];
  [optionalOptions: string]: any;
}

interface SearchOptionsPayload {
  page: number;
  page_size: number;
  order_by_direction: 'asc' | 'desc';
  order_by?: string;
  search_query?: string;
}

enum Status {
  SUCCESS = 'success',
  CONFIRMED = 'confirmed',
  ERROR = 'error',
  CANCEL = 'cancel',
}

interface SearchOptions {
  item_type?: string;
  from?: number;
  size?: number;
  query: {
    keywords: string;
    scope?: 'default' | 'name';
  };
  filters: SearchOptionFilters;
  multi_select_filters?: SearchOptionMultiSelectFilters;
  filter_args?: SearchBarFilterResourceArgs;
  custom_filters?: { [field: string]: string | string[] | boolean | undefined };
  custom_properties?: CustomSearchPropertiesType;
  sort?: ActionItem;
  excludes?: SearchOptionExcludes;
}

interface SearchOptionFilters {
  [field: string]: ActionItem | undefined;
}

interface SearchOptionMultiSelectFilters {
  [field: string]: ActionItem[] | undefined;
}

interface SearchOptionExcludes {
  [field: string]: string[] | undefined;
}

type CustomSearchPropertiesType = {
  [field: string]: boolean | string | undefined;
};

const DefaultSearchOptions: SearchOptions = {
  item_type: undefined,
  query: {
    keywords: '',
  },
  filters: {
    start: undefined,
    year: undefined,
    source: undefined,
    topic: undefined,
    category: undefined,
    type: undefined,
    framework: undefined,
    industry: undefined,
    tag: undefined,
    status: undefined,
    author: undefined,
    standard_codes: undefined,
  },
  custom_filters: undefined,
  custom_properties: undefined,
  sort: undefined,
  excludes: {
    framework_ids: undefined,
    categories: undefined,
  },
};

const default_filters = {
  start: false,
  year: false,
  source: false,
  topic: false,
  category: false,
  type: false,
  framework: false,
  industry: false,
  tag: false,
  status: false,
  author: false,
  standard_codes: false,
  taxonomy: false,
};

const SearchFilterConfig: { [key: string]: { [key: string]: boolean } } = {
  docs_doc: {
    ...default_filters,
    year: true,
    source: true,
    category: true,
    type: true,
    tag: true,
    status: true,
  },
  docs_report: {
    ...default_filters,
    year: true,
    source: true,
    category: true,
    type: true,
  },
  docs_resource: {
    ...default_filters,
    year: true,
    source: true,
    category: true,
    type: true,
    status: true,
  },
  library_peers_report: {
    ...default_filters,
    year: true,
    source: true,
    category: true,
    type: true,
  },
  library_peers_peer: {
    ...default_filters,
    industry: true,
    status: true,
  },
  metrics_indicator: {
    ...default_filters,
    topic: true,
    category: true,
    tag: true,
    standard_codes: true,
    taxonomy: true,
  },
  metrics_metric: {
    ...default_filters,
    topic: true,
    category: true,
    questionnaire_framework: true,
    tag: true,
    standard_codes: true,
  },
  metrics_template: {
    ...default_filters,
    topic: true,
    framework: true,
    industry: true,
  },
  metrics_code: {
    ...default_filters,
    year: true,
    topic: true,
    framework: true, // hidden
    industry: true,
  },
  sources_peer: {
    ...default_filters,
    category: true, // Countries
    framework: true, // hidden
    industry: true,
    status: true,
  },
  reports_report: {
    ...default_filters,
    year: true,
    source: true,
    topic: true,
    category: true,
    framework: true,
    tag: true,
    status: true,
  },
  reports_template: {
    ...default_filters,
    year: true,
    topic: true,
    category: true,
    framework: true,
    industry: true,
  },
  reports_indicators: {
    ...default_filters,
  },
  benchmarks_benchmark: {
    ...default_filters,
    tag: true,
  },
  analyze_dataset: {
    ...default_filters,
  },
  selection_set: {
    ...default_filters,
  },
  selection_set_item: {
    ...default_filters,
  },
  peers_peer: {
    ...default_filters,
    industry: true,
  },
  tags_tag: {
    ...default_filters,
  },
  unit_group: {
    ...default_filters,
  },
  unit_group_item: {
    ...default_filters,
  },
  standard_codes: {
    ...default_filters,
    topic: true,
    industry: true,
    standard_codes: true,
  },
  admin_standard_codes: {
    ...default_filters,
    start: true,
    topic: true,
  },
  framework_metric: {
    ...default_filters,
    topic: true,
    status: true,
  },
  peer_metric: {
    ...default_filters,
    topic: true,
    category: true,
    framework: true,
    industry: true,
  },
  users_user: {
    ...default_filters,
    status: true,
    role: true,
  },
  data_requests_request: {
    ...default_filters,
    year: true,
    source: true,
    // category: true, - hidden by NF-2251
    tag: true,
    status: true,
  },
  data_requests_template: {
    ...default_filters,
    tag: true,
  },
  // all data_requests_source filters are disabled for now and would be revisited during https://novisto.atlassian.net/browse/NF-1561
  data_requests_source: {
    ...default_filters,
  },
  sources_source: {
    ...default_filters,
    type: true,
  },
  metric_data_exports: {
    ...default_filters,
    year: true,
    source: true,
    type: true,
    tag: true,
    status: true,
  },
  admin_templates_metrics: {
    ...default_filters,
    topic: true,
    status: true,
  },
  integration_settings: {
    ...default_filters,
  },
};

export interface DataFormatTemplate<T extends BaseValue> {
  dataFormatType: ValueDefinitionDisplayType;
  data: T;
  id: string;
}

export interface UpdateDataFormatPayload {
  id: string;
  value: any;
  error?: boolean;
  unit?: string;
  type?: string;
}

export enum ValueDefinitionSize {
  small = 'small',
  medium = 'medium',
  medium_small = 'medium_small',
  large = 'large',
  large_small = 'large_small',
  large_medium = 'large_medium',
}

export enum ValueDefinitionDisplayType {
  text_area = 'text_area',
  text_area_validation = 'text_area_validation',
  text_rich = 'text_rich',
  text_simple = 'text_simple',
  number = 'number',
  table = 'table',
  choice = 'choice',
  choice_radio = 'choice_radio',
  choice_multiple = 'choice_multiple',
  choice_searchable = 'choice_searchable',
  boolean = 'boolean',
  date = 'date',
  datetime = 'datetime',
  tip = 'tip',
  subtitle = 'subtitle',
  calculated = 'calculated',
  file = 'file',
  file_v2 = 'file_v2',
  none = 'none',
  document = 'document',
  label = 'label',
  text = 'text',
  metric = 'metric',
}

export interface ValueGroupSetsTemplate<T extends BaseValue> {
  id: string;
  start?: string;
  end?: string;
  explanation: string;
  lastValueId: number;
  group: ValueGroupTemplate<T>[];
}

export interface ValueGroupTemplate<T extends BaseValue> {
  id?: string;
  value_definition_group_id?: string;
  repeatable?: boolean;
  position: number;
  subposition?: number | null;
  showOptions: boolean;
  showAddButton?: boolean;
  group_max_repetition?: number;
  indent?: number;
  value: ValueTemplate<T>[];
  conditional_triggers?: ConditionalTrigger[];
}

export interface ValueTemplate<T extends BaseValue> {
  id?: string;
  value_definition_id?: string;
  value: any;
  position?: number;
  size?: string;
  newline?: boolean;
  required?: boolean;
  hasError?: boolean;
  isEdited?: boolean;
  dataFormats: DataFormatTemplate<T>;
  unit?: string;
  hint?: string;
  file?: {
    id: string;
    name: string;
    format: string;
    url?: string;
    item: Doc;
  }[];
  conditional_triggers?: ConditionalTrigger[];
}

enum MetricsEditorToolsEnum {
  guidance = 'guidance',
  target = 'target',
  metric_notes = 'metric_notes',
  metric_status_transitions = 'metric_status_transitions',
  value_group_set_status_transitions = 'value_group_set_status_transitions',
  source_status_transitions = 'source_status_transitions',
  recommendations = 'recommendations',
  benchmarks = 'benchmarks',
  search_docs = 'search_docs',
  history = 'history',
  historical_data = 'historical_data',
  details = 'details',
  request_participants = 'request_participants',
}

enum SourceType {
  business_unit = 'business_unit',
  facility = 'facility',
  office = 'office',
  store = 'store',
  subsidiary = 'subsidiary',
  location = 'location',
  region = 'region',
  market = 'market',
  country = 'country',
  province = 'province',
  state = 'state',
  division = 'division',
}

enum SortFilter {
  // Common
  updated = 'updated',
  name = 'name',
  score = 'score',
  // Metrics
  start = 'start',
  description = 'description',
  // Selection-set, unit_group_item
  position = 'position',
}

enum MoveDirection {
  up = 'up',
  down = 'down',
}

enum ViewerActionType {
  hide = 'hide',
  disable = 'disable',
  preventMouseAction = 'preventMouseAction',
  show = 'show',
}

enum ToolsTabType {
  details = 'details',
  settings = 'settings',
  note = 'note',
  metric_notes = 'metric_notes',
  source_notes = 'source_notes',
  // Document, Benchmarks
  preview = 'preview',
  // data requests
  due_date = 'due_date',
}

export interface EmptyResults {
  title: string;
  subtitle?: string;
  image?: string;
  linkText?: string;
  linkAddress?: string;
  button?: string;
  btnTypeCssClass?: string;
  viewerAction?: ViewerActionType;
}

enum BtnType {
  primary = 'primary',
  success = 'success',
  error = 'error',
  secondary = 'secondary',
  stroked = 'stroked',
  filter = 'filter',
  option = 'option',
  fab = 'fab',
  warn = 'warn',
  loading = 'loading',
  profile = 'profile',
}

const BtnTypeCssClass: Record<BtnType, string> = {
  [BtnType.primary]: 'btn-primary',
  [BtnType.success]: 'btn-success',
  [BtnType.error]: 'btn-error',
  [BtnType.secondary]: 'btn-secondary',
  [BtnType.stroked]: 'btn-stroked',
  [BtnType.filter]: 'btn-filter',
  [BtnType.option]: 'btn-option',
  [BtnType.fab]: 'btn-fab',
  [BtnType.warn]: 'btn-warn',
  [BtnType.loading]: 'btn-loading',
  [BtnType.profile]: 'btn-profile',
};

export enum ChipType {
  stroked = 'stroked',
  primary = 'primary',
  light = 'light',
}

enum SupportedEnvironment {
  localhost = 'localhost',
  minivisto = 'local',
  dev = 'dev',
  qa = 'qa',
  experiment = 'experiment',
  staging = 'staging',
  production = 'production',
  green = 'green',
  blue = 'blue',
}

enum RelatedMetricsResourceType {
  standard_code = 'standard_code',
  imported_metric = 'imported_metric',
  core_metric = 'core_metric',
}

enum NavigationDirection {
  previous = 'previous',
  next = 'next',
}

export enum TechnicalProtocolItemType {
  field = 'field',
  metric = 'metric',
}

export interface MetricSearchValueDefinition {
  key: string;
  label: string;
  children?: MetricSearchValueDefinition[];
  disabled?: boolean;
}

export type MetricSearchValueDefinitionContainer = {
  nonRepeatable: MetricSearchValueDefinition[];
  repeatable: MetricSearchValueDefinition[];
};

export interface MetricSearchFieldSelection {
  attribute: MetricValueDefinitionAttribute;
  valueDefinition: ValueDefinition;
  valueDefinitionGroup: ValueDefinitionGroup;
}

export interface MetricSearchSelection {
  indicator: Indicator;
  valueDefinitions: MetricSearchFieldSelection[];
}

export interface MetricSearchInitialSelectionField {
  attribute: MetricValueDefinitionAttribute;
  valueDefinitionId: string;
}

export interface MetricSearchInitialSelectionMetric {
  metricId: string;
  valueDefinitions: MetricSearchInitialSelectionField[];
}

export interface MetricSearchInitialSelection {
  metrics: Indicator[];
  selections: MetricSearchInitialSelectionMetric[];
}

export interface ProgressDefinition {
  total: number;
  progress: number;
}

const DataRequestStatusIcon: Record<DataRequestStatus, { icon: Icon; color: string }> = {
  [DataRequestStatus.ACTIVE]: { icon: 'trend-up', color: 'success' },
  [DataRequestStatus.IMPORTED]: { icon: 'check', color: 'primary' },
  [DataRequestStatus.INACTIVE]: { icon: 'hide', color: 'dark' },
  [DataRequestStatus.ARCHIVED]: { icon: 'archive', color: 'dark' },
  [DataRequestStatus.DRAFT]: { icon: 'draft', color: 'dark' },
};

export interface BenchmarkData {
  data: Indicator;
  date: string;
  orgId?: string;
}

export interface MenuOption<T = string> {
  value: T;
  label: string;
}

export interface HeaderDropdownItem {
  id: string;
  title: string;
}

export interface OptionListOption {
  id?: string;
  label: string;
  position?: number;
}

export interface VgsetFilter {
  year?: ActionItem;
  source?: ActionItem;
}

export interface MetricValueDefinitionSelection {
  tableId?: string;
  valueDefinitionId?: string;
}

export enum StringForm {
  plural = 'plural',
  singular = 'singular',
}

export enum StringCase {
  lower = 'lower',
  sentense = 'sentence',
  title = 'title',
}

export enum LocalStorageKey {
  AI_SEARCH_DISABLED_SESSION_KEY = 'ai_search_disabled_session',
}
