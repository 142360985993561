<p-panelMenu [model]="panelMenuItems" [multiple]="multiple">
  <ng-template pTemplate="item" let-item>
    <div
      class="p-menuitem-link p-3 panel-item-container"
      [ngClass]="{ 'selected-item': selectedItems | includes: item.id }"
    >
      <div>
        <i
          *ngIf="item.items"
          [ngClass]="['icon-spacing', item.expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right']"
        ></i>
        <span> {{ item.label }}</span>
      </div>
      <i *ngIf="selectedItems | includes: item.id" class="pi pi-check" data-testid="menu-selected-icon"></i>
    </div>
  </ng-template>
</p-panelMenu>
