import { Component, Input, OnChanges } from '@angular/core';
import { ValueDefinitionSize } from '../../../models';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { ValidationMessageService } from '../../../services/common';

let nextId = 0;

@Component({
  selector: 'lib-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrl: './datetime-input.component.scss',
})
export class DatetimeInputComponent implements OnChanges {
  @Input() label = '';
  @Input() control?: UntypedFormControl;
  @Input() placeholder: string = '';
  @Input() hint: string = '';
  @Input() messages: ValidationErrors = {};
  @Input() format: string = 'yy-mm-dd';
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() readonly = false;
  @Input() minDate?: Date;
  @Input() maxDate?: Date;
  @Input() labelPosition: 'top' | 'left' = 'top';

  readonly _inputId = `datetime-input-${nextId++}`;

  required: boolean = false;
  errorMessages: ValidationErrors = {};

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
  }
}
