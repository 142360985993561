import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ButtonTypeEnum } from '../../../models';

type ButtonType = keyof typeof ButtonTypeEnum;
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'a[lib-button], button[lib-button], div[lib-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() buttonType?: ButtonType;
  @Input() @HostBinding() type: 'reset' | 'submit' | 'button' = 'button';
  @Input() @HostBinding('disabled') disabled: boolean = false;
  @Input() isLoading: boolean | null = false;
  @Input() isLarge: boolean = false;

  @HostBinding('attr.aria-label') @Input() label: string = '';
  @HostBinding('attr.role') role: string = 'button';
  @HostBinding('class') get classes(): string {
    return [
      'btn',
      this.getTypeClass(),
      this.disabled ? 'disabled btn-disabled' : '',
      this.isLoading ? 'btn-loading' : '',
      this.isLarge ? 'btn-lg' : '',
    ]
      .filter(Boolean)
      .join(' ');
  }

  private getTypeClass(): string {
    switch (this.buttonType) {
      case 'success':
        return 'btn-success';

      case 'primary':
        return 'btn-primary';

      case 'primary_v2':
        return 'btn-primary-v2';

      case 'link':
        return 'link';

      case 'browse':
        return 'browse';

      case 'danger':
        return 'btn-error color-white';

      case 'warning':
        return 'bkg-warning-accent color-white';

      case 'medium':
        return 'bkg-grey-600 color-white';

      case 'light':
        return 'btn-light';

      case 'stroked':
        return 'btn-stroked';

      case 'inherit':
        return 'btn-inherit';

      case 'stroked_light':
        return 'btn-stroked light';

      case 'color_link':
        return 'bkg-primary-500 color-white';

      case 'cancel_with_border':
        return 'btn-cancel-with-border';

      case 'cancel':
      default:
        return '';
    }
  }
}
