import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';

import { combineLatestWith, map } from 'rxjs/operators';
import { EMPTY, Observable, forkJoin, of, switchMap } from 'rxjs';

import { ActionItem, ApiResponse, FilterBarSelection, OptionList, OptionListCategory, ResourceType } from '../models';
import { OptionListsApiService } from '../services/api-services';
import { DEFAULT_PAGE_SIZE } from '../data-table';
import { ActionItemUtils } from '../classes';
import { FormControl } from '@angular/forms';

export interface PaginationState {
  currentPage: number;
  pageSize: number;
}

export interface FilterState {
  active?: string;
  orderBy?: string;
  orderByDirection?: string;
  searchQuery?: string;
  category?: string;
  type?: string;
}

export interface FilterOptionsState {
  optionListCategories?: ActionItem<OptionListCategory>[];
}

export interface OptionListCategoryDefaultItem {
  category: OptionListCategory;
  control: FormControl<OptionList | null>;
  isInitialized: boolean;
  isLoading: boolean;
  options: OptionList[];
  reachedEnd: boolean;
  searchTerm: string;
}

export type OptionListCategoryDefaultState = Record<string, OptionListCategoryDefaultItem>;

export interface OptionListState {
  filterOptionsState: FilterOptionsState;
  filterState: FilterState;
  isLoading: boolean;
  optionListCategoryDefault: OptionListCategoryDefaultState;
  optionLists: OptionList[];
  paginationState: PaginationState;
  total: number;
}

@Injectable()
export class OptionListsStore extends ComponentStore<OptionListState> {
  private static readonly DEFAULT_STATE: OptionListState = {
    filterOptionsState: { optionListCategories: undefined },
    filterState: { orderBy: 'name', orderByDirection: 'asc' },
    isLoading: true,
    optionListCategoryDefault: {},
    optionLists: [],
    paginationState: { currentPage: 1, pageSize: DEFAULT_PAGE_SIZE },
    total: 0,
  };

  public readonly filterOptionsState$: Observable<FilterOptionsState> = this.select(
    (state) => state.filterOptionsState,
  );
  public readonly filterState$: Observable<FilterState> = this.select((state) => state.filterState);
  public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);
  public readonly optionListCategoryDefault$: Observable<OptionListCategoryDefaultState> = this.select(
    (state) => state.optionListCategoryDefault,
  );
  public readonly optionLists$: Observable<OptionList[]> = this.select((state) => state.optionLists);
  public readonly paginationState$: Observable<PaginationState> = this.select((state) => state.paginationState);
  public readonly total$: Observable<number> = this.select((state) => state.total);

  constructor(private readonly optionListsApiService: OptionListsApiService) {
    super(OptionListsStore.DEFAULT_STATE);
  }

  public initialize(withDefaultColumn: boolean) {
    let defaultOptionListsObs: Observable<OptionList[]> = of([]);

    if (withDefaultColumn) {
      defaultOptionListsObs = this.optionListsApiService
        .listOptionLists({ is_category_default: true })
        .pipe(map((res) => res.data));
    }

    forkJoin([this.optionListsApiService.listOptionListCategories(), defaultOptionListsObs]).subscribe(
      ([res, defaultOptionLists]) => {
        const optionListCategoryDefault = res.data.reduce(
          (acc: OptionListCategoryDefaultState, category: OptionListCategory) => {
            const optionList = defaultOptionLists.find(
              (o) => (o.selection_set_category_id || o.core_option_list_category_id) === category.id,
            );
            acc[category.id] = {
              category,
              control: new FormControl<OptionList | null>(optionList || null),
              isInitialized: false,
              isLoading: false,
              options: optionList ? [optionList] : [],
              reachedEnd: false,
              searchTerm: '',
            };
            return acc;
          },
          {},
        );

        this.patchState({
          filterOptionsState: {
            optionListCategories: ActionItemUtils.responseToActionItem(res, ResourceType.option_list_category),
          },
          optionListCategoryDefault,
        });
      },
    );
    this.fetchOptionLists();
  }

  public updateSearchQuery(searchQuery?: string): void {
    this.updateSearchQueryState(searchQuery);
  }

  public updateFilters(filters: FilterBarSelection[]): void {
    const newFilterState: FilterState = {};
    for (const filter of filters) {
      if (filter.selection[0].id !== '-1') {
        newFilterState[filter.id as keyof FilterState] = filter.selection[0].id;
      } else {
        newFilterState[filter.id as keyof FilterState] = undefined;
      }
    }
    this.updateFiltersState(newFilterState);
  }

  public readonly updateOptionListCategoryDefaultState = this.updater(
    (state: OptionListState, item: OptionListCategoryDefaultItem): OptionListState => ({
      ...state,
      optionListCategoryDefault: { ...state.optionListCategoryDefault, [item.category.id]: item },
    }),
  );

  private readonly updateSearchQueryState = this.updater(
    (state: OptionListState, searchQuery?: string): OptionListState => ({
      ...OptionListsStore.DEFAULT_STATE,
      isLoading: false,
      filterOptionsState: state.filterOptionsState,
      filterState: { ...state.filterState, searchQuery },
    }),
  );

  public readonly updateFiltersState = this.updater(
    (state: OptionListState, newFilter: Partial<FilterState>): OptionListState => ({
      ...state,
      filterOptionsState: state.filterOptionsState,
      filterState: { ...state.filterState, ...newFilter },
    }),
  );

  public readonly updatePaginationState = this.updater(
    (state: OptionListState, paginationState: Partial<PaginationState>): OptionListState => ({
      ...state,
      paginationState: {
        currentPage: paginationState.currentPage ?? state.paginationState.currentPage,
        pageSize: paginationState.pageSize ?? state.paginationState.pageSize,
      },
    }),
  );

  private readonly updateOptionListsState = this.updater(
    (state: OptionListState, res: ApiResponse<OptionList[]>): OptionListState => ({
      ...state,
      optionLists: res.data,
      total: res.meta.total_count ?? 0,
    }),
  );

  public readonly fetchOptionLists = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.paginationState$, this.filterState$),
      switchMap(([_, paginationState, filterState]) =>
        this.optionListsApiService.listOptionLists({
          active: filterState.active ? filterState.active === 'active' : undefined,
          core_option_list_category_id: filterState.category,
          order_by: filterState.orderBy,
          order_by_direction: filterState.orderByDirection === 'asc' ? 'asc' : 'desc',
          page: paginationState.currentPage,
          page_size: paginationState.pageSize,
          search_term: filterState.searchQuery,
          selection_set_category_id: filterState.category,
          type: filterState.type,
        }),
      ),
      tapResponse(
        (apiResponse: ApiResponse<OptionList[]>) => {
          this.updateOptionListsState(apiResponse);
          this.patchState({ isLoading: false });
        },
        (_err) => {
          this.patchState({ isLoading: false });
          return EMPTY;
        },
      ),
    ),
  );
}
