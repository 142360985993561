<mat-stepper (selectionChange)="selectionChange($event)" [selectedIndex]="selectedIndex">
  <ng-container *ngFor="let step of steps">
    <mat-step
      *ngIf="step"
      [completed]="false"
      [label]="step.title ?? ''"
      [aria-labelledby]="step.disabled ? 'disabled_af' : ''"
    >
      <ng-template *ngIf="step.customLabel" matStepLabel>
        <ng-container *ngTemplateOutlet="step.customLabel"></ng-container>
      </ng-template>

      <ng-template *ngIf="step.lazyLoading; else eagerLoading" matStepContent>
        <ng-container *ngTemplateOutlet="step.content"></ng-container>
      </ng-template>

      <ng-template #eagerLoading>
        <ng-container *ngTemplateOutlet="step.content"></ng-container>
      </ng-template>
    </mat-step>
  </ng-container>
</mat-stepper>
