import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-readonly-rich-text',
  templateUrl: './readonly-rich-text.component.html',
  styleUrls: ['./readonly-rich-text.component.scss'],
})
export class ReadOnlyRichTextComponent {
  @Input({ required: true }) value!: string;

  public readonlyModules = {
    toolbar: false,
  };

  constructor() {}
}
