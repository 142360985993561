<div class="panel-properties-content-header mh-2">
  <div class="panel-properties-content-header-title fx-row align-center">
    <mat-icon svgIcon="calendar" class="mr-2"></mat-icon>
    <h4 class="mat-subtitle-2">{{ "Table totals" | translate }}</h4>
  </div>
  <div class="panel-properties-content-header-actions"></div>
</div>

<ng-container *ngIf="metricTableDefinition">
  <div class="panel-properties-content-container">
    <lib-metric-table-total-card
      [tableTotals]="calculationDefinitions"
      [metric]="metric"
      [metricTableDefinition]="metricTableDefinition"
      (deleteTotal)="deleteTableTotal($event)"
      (tableTotalClick)="openTableTotalDialog($event)"
      (moveTableTotalCard)="moveTableTotalCard($event)"
    >
    </lib-metric-table-total-card>

    <button
      lib-button
      (click)="openTableTotalDialog()"
      class="btn p-0 mt-2"
      *ngIf="!((metricTableDefinition | isCoreTableInPlatform: metric : isAdmin) && metricTableDeactivationEnabled)"
    >
      <mat-icon svgIcon="add" class="icon-md"></mat-icon>
      <div class="ml-1">{{ "Add Total" | translate }}</div>
    </button>
  </div>
</ng-container>
