import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

export const ValueDefinitionValidationErrorTranslation: { [key: string]: string } = {
  ['max_decimals']: _('change the decimals'),
  ['max_length']: _('change the character max length'),
  ['min_val']: _('change the minimum value'),
  ['max_val']: _('change the maximum value'),
  ['family']: _('change the unit family'),
  ['units']: _('change the unit default value'),
  ['multiple']: _('set the Allow multiple files to false'),
  ['max_files']: _('change the file attachment limit'),
  ['repeatable']: _('set the Make this a repeatable group to false'),
  ['group_max_repetition']: _('change the group repeat limit'),
  ['type']: _('change the type'),
  ['formula']: _('change the formula'),
  ['prompt_on_true']: _('deactivate the Display explanation field for true'),
  ['prompt_on_false']: _('deactivate the Display explanation field for false'),
  ['Table has values']: _('update the table'),
  ['widget_type']: _('change the Option List Display Type'),
  ['display_explanation']: _('deactivate the Explanation text field'),
  ['allow_add_option']: _('disallow users to manually enter options'),
  ['selection_set_id']: _('change the option list selected'),
  ['choices']: _('change the option list'),
  ['display_url']: _('deactivate the url field'),
  ['display_page_number']: _('deactivate the page number field'),
  ['max_date_range']: _('set the maximum date range for this field'),
  ['min_date_range']: _('set the minimum date range for this field'),
};

export const ValueDefinitionDeleteErrorTranslation: { [key: string]: string } = {
  ['value_definition_is_referenced_in_calculation']: _(
    'Please note that it is not possible to delete this field as it is used in another calculated field or data table.',
  ),
  ['Table has values']: _('Please note that it is not possible to delete this table because it has saved values.'),
};
export const ValueDefinitionMoveErrorTranslation: { [key: string]: string } = {
  ['value_definition_group_in_metric_table_error']: _('Fields cannot be moved to or from a metric table'),
  ['metric_mismatch']: _('Fields cannot be moved to a different metric'),
  ['metric_category_invalid_error']: _('Fields cannot be moved in a third party metric'),
  ['metric_category_reference_action_forbidden_error']: _('Fields cannot be moved to or from a core group'),
  ['conditional_trigger_invalid_position_error']: _(
    'This field is apart of a conditional trigger and cannot be moved to this group',
  ),
  ['value_group_repetitions_detected_error']: _('Fields cannot be moved to or from repeatable groups.'),
};
