<div class="card-list p-2">
  <div class="card-list-scroll" role="list">
    <lib-drag-item-card
      *ngFor="let item of items; let index = index"
      [item]="item"
      [menuItems]="menuItems[item.id]"
      (menuAction)="action.emit($event)"
      pDraggable="items"
      dragHandle=".item-card-drag-icon"
      pDroppable="items"
      (onDragStart)="onDragStart(index)"
      (onDragEnter)="onDragEnter(index)"
      (onDrop)="onDrop(item, index)"
    >
      <ng-template #afterTitle let-item>
        <ng-container
          *ngIf="afterTitleTmpl"
          [ngTemplateOutlet]="afterTitleTmpl"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
      </ng-template>
    </lib-drag-item-card>
  </div>
</div>
