import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';

import { filter, finalize, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { forkJoin, Observable, of, Subject } from 'rxjs';

import {
  ActionItem,
  Collection,
  ConfirmationDialogConfig,
  DialogSize,
  ItemType,
  Metric,
  MetricCategory,
  RelatedMetricSource,
  ResourceType,
  SearchOptions,
  StandardCodes,
  Status,
  Taxonomy,
} from '../../../../models';
import { ActionItemUtils } from '../../../../classes';
import { MetricApiService } from '../../../../services/types';
import { MetricStructureStateService } from '../../../services/metric-structure-state.service';
import { SearchService } from '../../../../search';
import { StandardCodesService } from '../../../../standard-codes';
import { TranslateService } from '../../../../services/common';
import { UniquenessValidator } from '../../../../validators';
import {
  AddTaxonomiesDialogComponent,
  AddTaxonomiesDialogComponentConfig,
  AddTaxonomiesDialogComponentResult,
} from '../../add-taxonomies-dialog/add-taxonomies-dialog.component';
import { ConfirmationDialogComponent, DialogsService } from '../../../../dialogs';
import { TaxonomiesCardComponent } from '../../taxonomies-card/taxonomies-card.component';
import sortBy from 'lodash/sortBy';
import { FeatureFlagService } from '../../../../feature-flag';

@Component({
  selector: 'lib-metric-structure-settings',
  templateUrl: './metric-structure-settings.component.html',
  styleUrls: ['./metric-structure-settings.component.scss'],
})
export class MetricStructureSettingsComponent implements OnInit, OnDestroy {
  public static readonly TAXONOMY_ORDER_BY: keyof Taxonomy = 'code';

  @ViewChild(TaxonomiesCardComponent) taxonomiesCard?: TaxonomiesCardComponent;

  @Output() closeProperties: EventEmitter<void> = new EventEmitter<void>();

  metric?: Metric;
  metricForm: UntypedFormGroup;
  codeFormControl: UntypedFormControl = new UntypedFormControl('', [Validators.required, Validators.maxLength(20)]);
  descriptionFormControl: UntypedFormControl = new UntypedFormControl('', Validators.required);
  guidanceFormControl: UntypedFormControl = new UntypedFormControl('');
  topicsFormControl: UntypedFormControl = new UntypedFormControl(null);
  tagsFormControl: UntypedFormControl = new UntypedFormControl(null);
  relatedMetricsFormControl: UntypedFormControl = new UntypedFormControl(null);
  standardCodesFormControl: UntypedFormControl = new UntypedFormControl(null);

  readonly codeFieldValidationMsgs: ValidationErrors = {
    required: this.translateService.instant('Code is required'),
    maxlength: this.translateService.instant('Code is too long'),
    isUnique: this.translateService.instant('This metric code already exists'),
  };

  readonly descriptionFieldValidationMsgs: ValidationErrors = {
    required: this.translateService.instant('Description is required'),
  };

  public taxonomies: Taxonomy[] = [];
  topicOptions: ActionItem[] = [];
  metricOptions: ActionItem[] = [];
  standardCodesOptions$: Observable<ActionItem[]> = of([]);
  readonly eMetricCategory: typeof MetricCategory = MetricCategory;

  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$;
  refMetricsV2EnabledFF: boolean = false;
  readonly isAdmin: boolean = this.metricStructureService.isAdmin;
  technicalProtocolEnabled: boolean = false;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    private metricsService: MetricApiService,
    private searchService: SearchService,
    private metricStructureService: MetricStructureStateService,
    private standardCodesService: StandardCodesService,
    private featureFlagService: FeatureFlagService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.metricForm = fb.group({
      codeFormControl: this.codeFormControl,
      descriptionFormConttrol: this.descriptionFormControl,
      guidanceFormControl: this.guidanceFormControl,
      topicsControl: this.topicsFormControl,
      relatedMetrics: this.relatedMetricsFormControl,
      compatibleWith: this.standardCodesFormControl,
      tagsControl: this.tagsFormControl,
    });
  }

  ngOnInit(): void {
    this.refMetricsV2EnabledFF = this.featureFlagService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']);

    this.searchService
      .searchResources(ResourceType.topic)
      .pipe(take(1))
      .subscribe((result) => {
        this.topicOptions = result.filter((topic) => topic.item.topic_group_id);
      });
    this.metricStructureService.metric$.subscribe((metric) => {
      this.initializeMetricProperties(metric);
    });
    this.metricStructureService.metric$
      .pipe(
        filter((metric): metric is Metric => Boolean(metric)),
        take(1),
        switchMap((metric) =>
          forkJoin([
            of(metric),
            this.metricsService.getRelatedMetrics(metric.id),
            this.isAdmin && metric.category === MetricCategory.THIRD_PARTY
              ? this.metricsService.getTaxonomies(metric.id, false, MetricStructureSettingsComponent.TAXONOMY_ORDER_BY)
              : of(null),
          ]),
        ),
      )
      .subscribe(([metric, relatedMetricsRes, taxonomiesRes]) => {
        const cloneMetric: Metric = Object.assign({}, metric);
        cloneMetric.related_metrics = relatedMetricsRes.data;
        this.metricStructureService.updateMetric(cloneMetric);
        this.taxonomies = taxonomiesRes?.data || [];
      });
    this.searchStandardCodes('');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  public addTaxonomies(): void {
    if (!this.metric) {
      return;
    }

    this.dialogsService
      .open<AddTaxonomiesDialogComponent, AddTaxonomiesDialogComponentConfig, AddTaxonomiesDialogComponentResult>(
        AddTaxonomiesDialogComponent,
        {
          data: { metric_id: this.metric.id, taxonomies: this.taxonomies, size: DialogSize.full },
          viewContainerRef: this.viewContainerRef,
        },
      )
      .afterClosed()
      .pipe(filter((dialogResult) => dialogResult?.status === Status.SUCCESS))
      .subscribe((dialogResult) => {
        if (dialogResult?.data) {
          this.taxonomies = sortBy(
            [...this.taxonomies, ...dialogResult.data.taxonomies],
            MetricStructureSettingsComponent.TAXONOMY_ORDER_BY,
          );
          this.taxonomiesCard?.expandableCard?.isExpanded && this.taxonomiesCard.expandableCard.toggleExpand();
        }
      });
  }

  public deleteTaxonomy(taxonomy: Taxonomy): void {
    if (!this.metric) {
      return;
    }

    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          primaryBtn: this.translateService.instant('Remove'),
          title: this.translateService.instant('Remove Novisto framework taxonomy'),
          warningMsg: this.translateService.instant(
            'Are you sure you wish to remove this framework taxonomy "{code}"?',
            { code: taxonomy.code },
          ),
        },
      })
      .afterClosed()
      .pipe(
        filter((dialogResult) => dialogResult?.status === Status.CONFIRMED),
        switchMap(() => this.metricsService.deleteTaxonomy(String(this.metric?.id), taxonomy.id)),
      )
      .subscribe(() => {
        this.taxonomies = this.taxonomies.filter((t) => t.id !== taxonomy.id);
      });
  }

  public searchStandardCodes(keywords: string): void {
    const searchOptions: SearchOptions = {
      item_type: ItemType.standard_codes,
      query: {
        keywords,
      },
      filters: {},
    };
    this.standardCodesOptions$ = this.standardCodesService.search(searchOptions).pipe(
      map((stdCodes) => stdCodes.map((stdCode) => ({ id: stdCode.id, title: stdCode.code, item: stdCode }))),
      takeUntil(this.destroy$),
    );
  }

  private getRelatedMetrics(keywords: string) {
    this.searchRelatedMetrics(keywords)
      .pipe(takeUntil(this.destroy$))
      .subscribe((collection) => {
        this.metricOptions = collection.items;
      });
  }

  private searchRelatedMetrics<T>(keywords: string): Observable<Collection<T>> {
    const searchOptions = {
      item_type: ItemType.related_metrics,
      query: {
        keywords,
      },
      filters: {},
      excludes: {},
      sort: {
        id: 'score',
        title: 'Best Match',
      },
    };

    switch (this.metric?.category) {
      case MetricCategory.THIRD_PARTY:
        searchOptions.excludes = {
          framework_ids: [this.metric.framework_id || ''],
        };
        break;
      case MetricCategory.REFERENCE:
        searchOptions.excludes = {
          categories: [MetricCategory.REFERENCE],
        };
        break;
    }

    return this.searchService.search(searchOptions) as Observable<Collection<T>>;
  }

  public updateMetricsOptions(keywords: string) {
    this.getRelatedMetrics(keywords);
  }

  public saveProperties(): void {
    if (this.metricForm.valid) {
      this.metricStructureService.updateIsMetricUpdating(true);
      this.metricsService
        .updateMetric(String(this.metric?.id), this.constructPayload())
        .pipe(
          finalize(() => {
            this.metricStructureService.updateIsMetricUpdating(false);
          }),
        )
        .subscribe((response) => {
          this.metricStructureService.updateMetric(response.data);
          this.metricForm.markAsPristine();
        });
    }
  }

  public closeSettings(): void {
    this.closeProperties.emit();
  }

  private constructPayload(): { [key: string]: string | any[] } {
    return {
      ...(this.codeFormControl.enabled && {
        code: `${this.metric?.category === MetricCategory.CUSTOM ? 'CUS ' : ''}${this.codeFormControl.value}`,
      }),
      ...(this.descriptionFormControl.enabled && { description: this.descriptionFormControl.value }),
      ...(this.guidanceFormControl.enabled && { guidance: this.guidanceFormControl.value }),
      ...(this.topicsFormControl.enabled && {
        topics: (this.topicsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.tagsFormControl.enabled && {
        tags: (this.tagsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.standardCodesFormControl.enabled && {
        standard_codes: (this.standardCodesFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
      ...(this.relatedMetricsFormControl.enabled && {
        metric_equivalents: (this.relatedMetricsFormControl.value as ActionItem[] | null)?.map((x) => x.id) ?? [],
      }),
    };
  }

  private initMetricFormControls(): void {
    if (this.isAdmin) {
      this.tagsFormControl.disable();
      const completeCode = this.metric?.code || '';
      const [prefix, ...codeComponents] = completeCode.split(' ');
      const code = codeComponents.join(' ');
      this.codeFormControl.clearAsyncValidators();
      this.codeFormControl.setValue(code);
      this.codeFormControl.addAsyncValidators(
        UniquenessValidator.validate(
          (code: string) =>
            this.metricsService.checkIfMetricCodeExists(code, prefix).pipe(map((res) => res.data.available)),
          [String(code)],
        ),
      );
      this.descriptionFormControl.setValue(this.metric?.description);
      this.guidanceFormControl.setValue(this.metric?.guidance);
      if (this.metric?.category !== MetricCategory.REFERENCE) {
        this.standardCodesFormControl.disable();
      }
    } else {
      switch (this.metric?.category) {
        case MetricCategory.CUSTOM:
          const code = this.metric.code?.split(' ').splice(1).join(' ');
          this.codeFormControl.clearAsyncValidators();
          this.codeFormControl.setValue(code);
          this.codeFormControl.addAsyncValidators(
            UniquenessValidator.validate(
              (code: string) =>
                this.metricsService.checkIfMetricCodeExists(code).pipe(map((res) => res.data.available)),
              [String(code)],
            ),
          );
          this.codeFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            if (!this.codeFormControl.touched) {
              this.codeFormControl.markAsTouched({ onlySelf: true });
            }
          });
          this.descriptionFormControl.setValue(this.metric.description);
          this.guidanceFormControl.setValue(this.metric.guidance);
          break;
        case MetricCategory.REFERENCE:
          this.codeFormControl.disable();
          this.descriptionFormControl.setValue(this.metric.description);
          this.topicsFormControl.disable();
          this.guidanceFormControl.setValue(this.metric.guidance);
          this.standardCodesFormControl.disable();
          break;
        case MetricCategory.THIRD_PARTY:
          this.codeFormControl.disable();
          this.descriptionFormControl.disable();
          this.topicsFormControl.disable();
          this.guidanceFormControl.disable();
          this.standardCodesFormControl.disable();
          break;
      }
    }
  }

  private initializeMetricProperties(metric?: Metric): void {
    this.metric = metric;
    this.technicalProtocolEnabled =
      this.refMetricsV2EnabledFF && (this.metric?.reference_v2 || this.metric?.category === MetricCategory.CUSTOM);
    this.initMetricFormControls();
    this.initMetricFormControlsValue();
  }

  private initMetricFormControlsValue(): void {
    this.topicsFormControl.setValue(
      this.metric?.topics?.map((topic) => ({ id: topic.id, title: topic.name, item: topic })) ?? [],
    );
    if (this.metric?.related_metrics) {
      const equivalent = this.metric.related_metrics.filter(
        (rm) =>
          rm.original_metric_ids.includes(String(this.metric?.id)) &&
          (this.isAdmin || rm.source === RelatedMetricSource.platform),
      );
      const relatedMetrics = equivalent.map((e) => e.equivalent_metric);
      const relatedMetricsActionItems: ActionItem[] = ActionItemUtils.resourcesToActionItem(
        relatedMetrics,
        ResourceType.metrics_indicator,
      );
      this.relatedMetricsFormControl.setValue(relatedMetricsActionItems);
    }
    this.tagsFormControl.setValue(this.metric?.tags?.map((tag) => ({ id: tag.id, title: tag.label, item: tag })) ?? []);
    this.standardCodesFormControl.setValue(
      this.metric?.standard_codes?.map((stdCode: StandardCodes) => ({
        id: stdCode.id,
        title: stdCode.code,
        item: stdCode,
      })),
    );
  }
}
