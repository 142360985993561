export enum Permission {
  USERS_VIEW = 'USERS_VIEW',
  USERS_MANAGE = 'USERS_MANAGE',
  // Search
  SEARCH_DOCUMENTS = 'SEARCH_DOCUMENTS',
  SEARCH_RESOURCES = 'SEARCH_RESOURCES',
  SEARCH_INDICATORS = 'SEARCH_INDICATORS',
  SEARCH_METRICS = 'SEARCH_METRICS',
  // Core Data (Industries, Orgs, Business Units, Frameworks, Selection Sets, Units, etc.)
  CORE_DATA_VIEW = 'CORE_DATA_VIEW',
  CORE_METRICS_VIEW = 'CORE_METRICS_VIEW',
  CORE_REPORT_TEMPLATES_VIEW = 'CORE_REPORT_TEMPLATES_VIEW',
  CORE_DOCUMENTS_VIEW = 'CORE_DOCUMENTS_VIEW',
  // Favorite Orgs
  FAVORITE_ORGS_VIEW = 'FAVORITE_ORGS_VIEW',
  FAVORITE_ORGS_MANAGE = 'FAVORITE_ORGS_MANAGE',
  // Metrics
  METRICS_VIEW = 'METRICS_VIEW',
  METRICS_MANAGE = 'METRICS_MANAGE',
  METRICS_MANAGE_TAGS = 'METRIC_MANAGE_TAGS',
  METRICS_IMPORT = 'METRICS_IMPORT',
  // Indicators
  INDICATORS_VIEW = 'INDICATORS_VIEW',
  INDICATORS_MANAGE = 'INDICATORS_MANAGE',
  // Platform Data (Business Units, Fiscal Years)
  PLATFORM_DATA_VIEW = 'PLATFORM_DATA_VIEW',
  // Documents
  DOCUMENTS_VIEW = 'DOCUMENTS_VIEW',
  DOCUMENTS_EDIT = 'DOCUMENTS_EDIT',
  DOCUMENTS_DELETE = 'DOCUMENTS_DELETE',
  // Reports
  REPORTS_VIEW = 'REPORTS_VIEW',
  REPORTS_MANAGE = 'REPORTS_MANAGE',
  // Benchmarks
  BENCHMARKS_VIEW = 'BENCHMARKS_VIEW',
  BENCHMARKS_MANAGE = 'BENCHMARKS_MANAGE',
  // Tags
  TAGS_VIEW = 'TAGS_VIEW',
  TAGS_MANAGE = 'TAGS_MANAGE',
  // Targets
  TARGETS_VIEW = 'TARGETS_VIEW',
  TARGETS_MANAGE = 'TARGETS_MANAGE',
  // Business Units
  BUSINESS_UNITS_VIEW = 'BUSINESS_UNITS_VIEW',
  BUSINESS_UNITS_MANAGE = 'BUSINESS_UNITS_MANAGE',
  // Data Requests
  DATA_REQUESTS_VIEW = 'DATA_REQUESTS_VIEW',
  DATA_REQUESTS_MANAGE = 'DATA_REQUESTS_MANAGE',
  DATA_REQUESTS_TEMPLATE_VIEW = 'DATA_REQUESTS_TEMPLATE_VIEW',
  DATA_REQUESTS_TEMPLATE_MANAGE = 'DATA_REQUESTS_TEMPLATE_MANAGE',
  DATA_REQUESTS_COLLECT_VIEW = 'DATA_REQUESTS_COLLECT_VIEW',
  DATA_REQUESTS_COLLECT_FILL = 'DATA_REQUESTS_COLLECT_FILL',
  // User Groups
  USER_GROUPS_VIEW = 'USER_GROUPS_VIEW',
  USER_GROUPS_MANAGE = 'USER_GROUPS_MANAGE',
  // Frameworks (Core-Specific)
  FRAMEWORKS_VIEW = 'FRAMEWORKS_VIEW',
  FRAMEWORKS_MANAGE = 'FRAMEWORKS_MANAGE',
  // Industries (Core-Specific)
  INDUSTRIES_VIEW = 'INDUSTRIES_VIEW',
  INDUSTRIES_MANAGE = 'INDUSTRIES_MANAGE',
  // Templates (Core-Specific)
  TEMPLATES_VIEW = 'TEMPLATES_VIEW',
  TEMPLATES_MANAGE = 'TEMPLATES_MANAGE',
  // Client Configs (Core-Specific)
  CLIENT_CONFIG_VIEW = 'CLIENT_CONFIG_VIEW',
  CLIENT_CONFIG_MANAGE = 'CLIENT_CONFIG_MANAGE',
  // Orgs (Core-Specific)
  ORG_VIEW = 'ORG_VIEW',
  ORG_MANAGE = 'ORG_MANAGE',
  // Selection Sets (Core-Specific)
  SELECTION_SETS_VIEW = 'SELECTION_SETS_VIEW',
  SELECTION_SETS_MANAGE = 'SELECTION_SETS_MANAGE',
  // Standard Codes (Core-Specific)
  STANDARD_CODES_VIEW = 'STANDARD_CODES_VIEW',
  STANDARD_CODES_MANAGE = 'STANDARD_CODES_MANAGE',
  // Topics
  TOPICS_VIEW = 'TOPICS_VIEW',
  // Unit Groups (Core-Specific)
  UNIT_GROUPS_VIEW = 'UNIT_GROUPS_VIEW',
  UNIT_GROUPS_MANAGE = 'UNIT_GROUPS_MANAGE',
  // Units (Core-Specific)
  UNITS_VIEW = 'UNITS_VIEW',
  // Metric Groups
  METRIC_GROUPS_VIEW = 'METRIC_GROUPS_VIEW',
  METRIC_GROUPS_MANAGE = 'METRIC_GROUPS_MANAGE',
  // Licensee Settings
  MANAGE_LICENSEE_SETTINGS = 'MANAGE_LICENSEE_SETTINGS',
  // Fiscal Years
  FISCAL_YEARS_VIEW = 'FISCAL_YEARS_VIEW',
  FISCAL_YEARS_MANAGE = 'FISCAL_YEARS_MANAGE',
  // Add-in
  ADD_IN_VIEW = 'ADD_IN_VIEW',
  // Options Lists
  OPTION_LISTS_VIEW = 'OPTION_LISTS_VIEW',
  OPTION_LISTS_MANAGE = 'OPTION_LISTS_MANAGE',
  // Data export
  DATA_EXPORT_VIEW = 'DATA_EXPORT_VIEW',
  DATA_EXPORT_MANAGE = 'DATA_EXPORT_MANAGE',
  // Dashboards
  DASHBOARDS_VIEW = 'DASHBOARDS_VIEW',
  DASHBOARDS_MANAGE = 'DASHBOARDS_MANAGE',
  DASHBOARDS_ADMIN = 'DASHBOARDS_ADMIN',
  // Integration Partner Login
  INTEGRATION_PARTNER_LOGIN_VIEW = 'INTEGRATION_PARTNER_LOGIN_VIEW',
  // Benchmarks V2
  BENCHMARKS_V2_MANAGE = 'BENCHMARKS_V2_MANAGE',
  // Job Queue View
  JOB_QUEUE_VIEW = 'JOB_QUEUE_VIEW',
  // Field Audit Export
  FIELD_AUDIT_EXPORT_MANAGE = 'FIELD_AUDIT_EXPORT_MANAGE',
  // Data Tables V2
  DATA_TABLES_V2_MANAGE = 'DATA_TABLES_V2_MANAGE',
  // Client Branding
  CLIENT_BRANDING_MANAGE = 'CLIENT_BRANDING_MANAGE',
}
