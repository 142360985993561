import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'lib-expandable-text',
  templateUrl: './expandable-text.component.html',
  styleUrls: ['./expandable-text.component.scss'],
})
export class ExpandableTextComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() text: string = '';
  @Input() numberOfLineToShow: number = 4; // only applicable if isHtmlText = true
  @Input() charactersToShow: number = 100; // only applicable if isHtmlText = false
  @Input() isHtmlText: boolean = true;

  @ViewChild('textContent') set textContent(textContentRef: ElementRef | undefined) {
    if (textContentRef) {
      this.textContentRef = textContentRef;
    }
  }

  readMore: boolean = false;
  showExpandable: boolean = true;
  lineMaxHeight: string = '64px';
  textContentRef?: ElementRef;

  readonly lineHeight: number = 16;

  private observer$: ResizeObserver;
  private onResize$ = new Subject<void>();

  constructor(
    private cdr: ChangeDetectorRef,
    private destroyRed: DestroyRef,
    private readonly zone: NgZone,
  ) {
    this.observer$ = new ResizeObserver(() =>
      this.zone.run((_entries) => {
        this.onResize$.next();
      }),
    );
  }

  private isStartingWithList(text: string) {
    return text.startsWith('<ol>') || text.startsWith('<ul>');
  }

  ngOnInit(): void {
    if (this.isStartingWithList(this.text) && this.numberOfLineToShow === 1) {
      this.numberOfLineToShow = 2;
    }
    this.lineMaxHeight = `${this.numberOfLineToShow * this.lineHeight}px`;

    this.initializeResizeListener();
  }

  ngAfterViewInit(): void {
    if (this.textContentRef?.nativeElement) {
      this.observer$.observe(this.textContentRef.nativeElement as Element);
    }

    this.setShowExpandable();
  }

  private initializeResizeListener(): void {
    this.onResize$.pipe(debounceTime(100), takeUntilDestroyed(this.destroyRed)).subscribe(() => {
      this.setShowExpandable();
    });
  }

  public setShowExpandable(): void {
    if (!this.textContentRef) {
      return;
    }

    const nativeElement = this.textContentRef.nativeElement;
    this.showExpandable = nativeElement ? nativeElement.offsetHeight <= nativeElement.firstChild?.offsetHeight : false;

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    this.observer$.disconnect();
  }
}
