<lib-item-card [item]="item" [menuItems]="menuItems" [disabled]="disabled" (menuAction)="menuAction.emit($event)">
  <ng-template #action>
    <i class="pi pi-bars item-card-drag-icon" data-testid="itemCardDragHandle"></i>
  </ng-template>
  <ng-template #afterTitle>
    <ng-container
      *ngIf="afterTitleTmpl"
      [ngTemplateOutlet]="afterTitleTmpl"
      [ngTemplateOutletContext]="{ $implicit: item }"
    ></ng-container>
  </ng-template>
</lib-item-card>
