<div *ngIf="control || uncontrolled" [class]="'formfield ' + size" [ngClass]="{ disabled: control?.disabled }">
  <mat-slide-toggle
    *ngIf="control; else uncontrolledInput"
    title="{{ label | translate }}"
    [formControl]="control"
    (change)="sliderToggle.emit($event.checked)"
    [hideIcon]="true"
    data-testid="slide-toggle"
    color="secondary"
  >
    {{ label }}
  </mat-slide-toggle>

  <ng-template #uncontrolledInput>
    <mat-slide-toggle
      [id]="id"
      title="{{ label | translate }}"
      [aria-labelledby]="id"
      [attr.name]="id"
      [checked]="checked"
      [disabled]="disabled"
      (change)="sliderToggle.emit($event.checked)"
      [hideIcon]="true"
      data-testid="slide-toggle"
    >
      {{ label }}
    </mat-slide-toggle>
  </ng-template>

  <mat-hint *ngIf="hint" class="w-100">
    <span>{{ hint }}</span>
  </mat-hint>
</div>
