import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { forkJoin, map, pairwise, Subject, takeUntil, startWith } from 'rxjs';
import { ValueFormControl } from '../../models/valueFormControl';
import {
  ChoiceFieldWidgetType,
  ChoiceTypeDetails,
  ConfirmationDialogConfig,
  DialogResult,
  Indicator,
  ReportIntegrationType,
  OptionListItem,
  Status,
  ValueDefinitionSize,
  OptionList,
  ChoiceValue,
} from '../../../models';
import {
  MultiSelectChipInputComponent,
  SearchableSelectInputComponent,
  SearchableSelectInputParameters,
} from '../../../components';
import { MetricEditorCheckboxFieldComponent } from './metric-editor-checkbox-field/metric-editor-checkbox-field.component';
import { MetricEditorRadioFieldComponent } from './metric-editor-radio-field/metric-editor-radio-field.component';
import { ChoiceFormGroup } from './forms/metric-editor-choice-field-form';
import { MatChipInputEvent } from '@angular/material/chips';
import { StringUtils } from '../../../classes';
import { ValueDefinitionConstants } from '../../../constants';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { TranslateService } from '../../../services/common';
import { OptionListsApiService } from '../../../services/api-services';

@Component({
  selector: 'lib-metric-editor-choice-field',
  templateUrl: './metric-editor-choice-field.component.html',
  styleUrls: ['./metric-editor-choice-field.component.scss'],
})
export class MetricEditorChoiceFieldComponent implements OnInit, OnDestroy, DoCheck {
  @Input({ required: true }) valueFormControl!: ValueFormControl<ChoiceTypeDetails>;
  @Input() isPublic: boolean = false;
  @Input() indicator?: Indicator;
  @Input() integrationType: ReportIntegrationType | null = null;

  @ViewChild('inputFieldRef') inputFieldRef!:
    | SearchableSelectInputComponent<string>
    | MetricEditorCheckboxFieldComponent
    | MultiSelectChipInputComponent
    | MetricEditorRadioFieldComponent;

  readonly eChoiceFieldWidgetType = ChoiceFieldWidgetType;

  private destroy$ = new Subject<void>();

  choiceFormGroup?: ChoiceFormGroup;

  hint: string = '';
  label: string = '';
  widgetType: ChoiceFieldWidgetType = ChoiceFieldWidgetType.single_select;
  multiChoice: boolean = false;
  choicesList: string[] = [];
  filteredChoiceList: string[] = [];
  displayExplanation: boolean = false;
  explanationLabel: string = '';
  explanationRequired: boolean = false;
  explanationMaxLength: number = ValueDefinitionConstants.DEFAULT_EXPLANATION_MAX_LENGTH;
  allowAddOption: boolean = false;
  size: ValueDefinitionSize = ValueDefinitionSize.large;
  optionList?: OptionList;
  optionListItems: OptionListItem[] = [];

  constructor(
    private dialogsService: DialogsService,
    private translateService: TranslateService,
    private optionListsApiService: OptionListsApiService,
  ) {}

  ngOnInit(): void {
    this.initChoiceList();
    this.subscribeToValuesControlChanges();
  }

  ngOnDestroy(): void {
    this.updateField();
    this.choiceFormGroup?.destroy();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngDoCheck() {
    // TODO: Replace with subscription when this is merged :
    // https://github.com/angular/angular/issues/10887
    this.choiceFormGroup?.syncTouchedStatus();
  }

  setFocus(): void {
    this.inputFieldRef.setFocus();
  }

  private init(): void {
    this.initProperties();
    this.choiceFormGroup = new ChoiceFormGroup(
      this.valueFormControl,
      {
        multiChoice: this.multiChoice,
        displayExplanation: this.displayExplanation,
        explanationRequired: this.explanationRequired,
      },
      this.optionListItems,
    );
  }

  private initChoiceList(): void {
    const typeDetails = this.valueFormControl.valueRef.type_details;
    const selectionSetId = typeDetails.selection_set_id;
    if (selectionSetId) {
      const applyCDPTagsFiltering = this.integrationType == ReportIntegrationType.CDP;
      forkJoin([
        this.optionListsApiService.listOptionListItemsFromSource(
          selectionSetId,
          { order_by: 'position', order_by_direction: 'asc' },
          typeDetails.selection_set_source,
          this.isPublic,
          applyCDPTagsFiltering,
        ),
        this.optionListsApiService.getOptionListFromSource(
          selectionSetId,
          typeDetails.selection_set_source,
          this.isPublic,
        ),
      ]).subscribe({
        next: ([optionListItemsRes, optionListRes]) => {
          this.optionListItems = optionListItemsRes.data;
          this.optionList = optionListRes.data;
          this.choicesList = optionListItemsRes.data.map((item) => item.name);
          this.filteredChoiceList = [...this.choicesList];
          this.init();
        },
      });
    }
  }

  private initProperties() {
    this.hint = this.valueFormControl.valueRef.hint ?? '';
    this.label = this.valueFormControl.valueRef.label ?? '';
    this.widgetType = this.valueFormControl.valueRef.type_details.widget_type ?? ChoiceFieldWidgetType.single_select;

    if (this.valueFormControl.valueRef.type_details.selection_set_apply_all) {
      this.displayExplanation = this.valueFormControl.valueRef.type_details.display_explanation ?? false;
      this.explanationRequired = this.valueFormControl.valueRef.type_details.explanation_required ?? false;
      this.explanationLabel = this.valueFormControl.valueRef.type_details.explanation_label ?? '';
    } else if (
      !this.valueFormControl.valueRef.type_details.selection_set_apply_all &&
      this.optionList &&
      this.optionListItems.length &&
      this.valueFormControl?.value
    ) {
      const values: string[] = this.valueFormControl.value.values || [];
      const displayExplanationItems = this.optionListItems.filter((i) => i.display_explanation);
      const explanationRequiredItems = this.optionListItems.filter((i) => i.explanation_required);
      this.displayExplanation = displayExplanationItems.some((i) => values.includes(i.name));
      this.explanationRequired = explanationRequiredItems.some((i) => values.includes(i.name));
      this.explanationLabel = this.optionList.explanation_label ?? '';
    }
    this.size = this.valueFormControl.valueRef.size;
    this.multiChoice = this.valueFormControl.valueRef.type_details.multi_choices ?? false;
    this.allowAddOption = this.valueFormControl.valueRef.type_details.allow_add_option ?? false;
  }

  private subscribeToValuesControlChanges(): void {
    this.valueFormControl.valueChanges
      .pipe(
        startWith(this.valueFormControl.value),
        pairwise(),
        map(
          ([previousValue, currentValue]): {
            previousValue?: ChoiceValue;
            displayExplanation: boolean;
          } => {
            const values: string[] = currentValue?.values || [];
            return {
              previousValue,
              displayExplanation: this.checkIfDisplayExplanationEnabledForSelectedOptions(values),
            };
          },
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(({ previousValue, displayExplanation }) => {
        this.updateTextFormControl(displayExplanation, previousValue?.values || []);
      });
  }

  private checkIfDisplayExplanationEnabledForSelectedOptions(selectedValues: string | string[]): boolean {
    if (this.valueFormControl.valueRef.type_details.selection_set_apply_all) {
      return this.valueFormControl.valueRef.type_details.display_explanation ?? false;
    }

    const valuesArray = Array.isArray(selectedValues) ? selectedValues : [selectedValues];
    return this.optionListItems.filter((i) => i.display_explanation).some((i) => valuesArray.includes(i.name));
  }

  private confirmTextRemovalDialog(previousValue: string | string[]): void {
    this.dialogsService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          title: this.translateService.instant('Change selection'),
          primaryBtn: this.translateService.instant('OK'),
          warningMsg: this.translateService.instant('Are you sure you want to update this option?'),
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result?: DialogResult) => {
        if (result?.status === Status.CONFIRMED) {
          this.init();
        } else {
          const prevValue = Array.isArray(this.choiceFormGroup?.valuesControl.value) ? previousValue : previousValue[0];
          this.choiceFormGroup?.valuesControl.setValue(prevValue, { emitEvent: true });
          this.choiceFormGroup?.updateValueFormControl(true);
        }
      });
  }

  private updateTextFormControl(displayExplanation: boolean, previousValue?: string | string[]): void {
    if (!displayExplanation && this.choiceFormGroup?.additionalTextControl?.value && previousValue) {
      this.confirmTextRemovalDialog(previousValue);
    } else {
      this.init();
    }
  }

  public addValue(event: MatChipInputEvent): void {
    const value = event.value;
    const currentValues = this.choiceFormGroup?.valuesControl.value as string[] | null;
    this.choiceFormGroup?.valuesControl.setValue([...(currentValues ?? []), value]);
    this.choiceFormGroup?.valuesControl.markAsTouched();
    this.choiceFormGroup?.valuesControl.markAsDirty();
  }

  public filterChoices(choiceList: string[], params?: SearchableSelectInputParameters): void {
    this.filteredChoiceList = choiceList.filter((choice) =>
      StringUtils.includesSubstring(choice, params?.searchValue ?? ''),
    );
  }

  public updateField(): void {
    this.choiceFormGroup?.blurAdditionalTextControl();
  }
}
