import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { combineLatestWith, filter } from 'rxjs/operators';
import { EMPTY, Observable, switchMap } from 'rxjs';

import { ActionItem, ApiResponse, OptionList, ResourceType } from '../../models';
import { OptionListsApiService } from '../../services/api-services';
import { OptionListItem } from '../../models';
import { ActionItemUtils } from '../../classes';
import keyBy from 'lodash/keyBy';
import { tapResponse } from '@ngrx/operators';

export interface OptionListState {
  isLoading: boolean;
  resourceList?: OptionList;
  resourceListItems: Record<string, ActionItem<OptionListItem>>;
  resourceLists: OptionList[];
}

@Injectable()
export class OptionListResourceListsStore extends ComponentStore<OptionListState> {
  private static readonly DEFAULT_STATE: OptionListState = {
    isLoading: true,
    resourceListItems: {},
    resourceLists: [],
  };

  public readonly isLoading$: Observable<boolean> = this.select((state) => state.isLoading);
  public readonly resourceList$: Observable<OptionList> = this.select((state) => state.resourceList).pipe(
    filter((resourceList): resourceList is OptionList => Boolean(resourceList)),
  );
  public readonly resourceListItems$: Observable<Record<string, ActionItem<OptionListItem>>> = this.select(
    (state) => state.resourceListItems,
  );
  public readonly resourceLists$: Observable<OptionList[]> = this.select((state) => state.resourceLists);

  private optionListCategoryId: string = '';

  constructor(private readonly optionListsApiService: OptionListsApiService) {
    super(OptionListResourceListsStore.DEFAULT_STATE);
  }

  public initialize(optionList: OptionList): void {
    this.optionListCategoryId = optionList.core_option_list_category_id || optionList.selection_set_category_id || '';
    this.optionListsApiService.listResourceLists(this.optionListCategoryId).subscribe((res) => {
      this.patchState({ isLoading: false, resourceLists: res.data });
    });
    this.fetchResourceListItems();
  }

  public readonly updateResourceListState = this.updater(
    (state: OptionListState, resourceList: OptionList): OptionListState => ({ ...state, resourceList }),
  );

  private readonly updateResourceListItemsState = this.updater(
    (state: OptionListState, resourceListItems: OptionListItem[]): OptionListState => ({
      ...state,
      resourceListItems: keyBy(
        ActionItemUtils.resourcesToActionItem(resourceListItems, ResourceType.option_list_item),
        'id',
      ),
    }),
  );

  public readonly fetchResourceListItems = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.resourceList$),
      switchMap(([_, resourceList]) => this.optionListsApiService.listResourceListItems(resourceList.id)),
      tapResponse(
        (res: ApiResponse<OptionListItem[]>) => {
          this.updateResourceListItemsState(res.data);
        },
        (_err) => EMPTY,
      ),
    ),
  );
}
