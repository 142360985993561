import { Validators } from '@angular/forms';
import { DynamicFormConfig } from '../../../../../classes';
import { MetricStructurePropertiesValidators } from '../../validators/metric-structure-properties-validators';
import { ValidatorsUtils } from '../../../../../classes/FormUtils/validators-utils';
import {
  ActionItem,
  CalculatedValueReferences,
  ChoiceFieldWidgetType,
  ConsolidationRules,
  ConsolidationTriggers,
  Doc,
  ValueDefinitionSize,
  ValueDefinitionType,
} from '../../../../../models';

export interface ConsolidationFormModel {
  consolidationRule: ConsolidationRules;
  consolidationTrigger?: ConsolidationTriggers;
  bypassConsolidationLevels?: string[] | null;
}

export interface InstructionFieldFormModel {
  instruction: string;
  instructionCard: ActionItem;
}
export const INSTRUCTION_FORM_CONFIG: DynamicFormConfig<InstructionFieldFormModel> = {
  instruction: {
    validators: [Validators.required],
  },
  instructionCard: {
    validators: [Validators.required],
  },
};

export interface SubtitleFieldFormModel {
  subtitle: string;
}
export const SUBTITLE_FORM_CONFIG: DynamicFormConfig<SubtitleFieldFormModel> = {
  subtitle: {
    validators: [Validators.required, Validators.maxLength(100), ValidatorsUtils.isNotOnlySpaces],
  },
};

export interface RichTextFieldFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  hint: string;
}
export const RICH_TEXT_FORM_CONFIG: DynamicFormConfig<RichTextFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  hint: {
    validators: [Validators.maxLength(150)],
  },
};

export interface DateFieldFormModel extends ConsolidationFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  hint: string;
  dateFormat: string;
  minDateRange: string;
  maxDateRange: string;
  displaySize: ValueDefinitionSize;
  toggleNewLine: boolean;
}
export const DATE_FORM_CONFIG: DynamicFormConfig<DateFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  toggleRequired: {},
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  hint: {
    validators: [Validators.maxLength(50)],
  },
  dateFormat: {
    validators: [Validators.required],
  },
  minDateRange: {},
  maxDateRange: {},
  displaySize: {
    validators: [Validators.required],
  },
  toggleNewLine: {},
};

export interface TextFieldFormModel extends ConsolidationFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  toggleTextarea: boolean;
  placeholder: string;
  hint: string;
  characterLimit: string;
  displaySize: ValueDefinitionSize;
  toggleNewLine: boolean;
}
export const TEXT_FORM_CONFIG: DynamicFormConfig<TextFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  toggleTextarea: {},
  placeholder: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  characterLimit: {
    validators: [MetricStructurePropertiesValidators.isNumber],
  },
  displaySize: {
    validators: [Validators.required],
  },
  toggleNewLine: {},
};

export interface BooleanFieldFormModel extends ConsolidationFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  hint: string;
  promptOnTrue: boolean;
  labelTrue: string;
  labelPromptTrue: string;
  requiredOnTrue: boolean;
  promptOnFalse: boolean;
  labelFalse: string;
  labelPromptFalse: string;
  requiredOnFalse: boolean;
}
export const BOOLEAN_FORM_CONFIG: DynamicFormConfig<BooleanFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  promptOnTrue: {},
  labelTrue: { validators: [Validators.required] },
  labelPromptTrue: {},
  requiredOnTrue: {},
  promptOnFalse: {},
  labelFalse: { validators: [Validators.required] },
  labelPromptFalse: {},
  requiredOnFalse: {},
};

export interface NumberFieldFormModel extends ConsolidationFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  hint?: string;
  unitFamily: string;
  units: string;
  decimals?: number;
  minimum?: string;
  maximum?: string;
  type: ValueDefinitionType;
  displaySize: ValueDefinitionSize;
  toggleNewLine: boolean;
}
export const NUMBER_FORM_CONFIG: DynamicFormConfig<NumberFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  unitFamily: {
    validators: [Validators.required],
  },
  units: {
    validators: [Validators.required],
  },
  decimals: {
    validators: [Validators.required],
  },
  minimum: {},
  maximum: {},
  type: {},
  displaySize: {
    validators: [Validators.required],
  },
  toggleNewLine: {},
  consolidationRule: { validators: [Validators.required] },
  consolidationTrigger: { validators: [Validators.required] },
  bypassConsolidationLevels: {},
};

export interface CalculatedFieldFormModel {
  label: string;
  shortLabel: string;
  unitFamily: string;
  units: string;
  decimals: number;
  formula: string;
  variables?: Record<string, CalculatedValueReferences>;
  displaySize: ValueDefinitionSize;
  toggleNewLine: boolean;
  mandatory: boolean;
}
export const CALCULATED_FORM_CONFIG: DynamicFormConfig<CalculatedFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  unitFamily: {
    validators: [Validators.required],
  },
  units: {
    validators: [Validators.required],
  },
  decimals: {
    validators: [Validators.required],
  },
  formula: {
    validators: [Validators.required],
  },
  variables: {},
  displaySize: {
    validators: [Validators.required],
  },
  toggleNewLine: {},
  mandatory: {},
};

export interface FileAttachmentFieldFormModel {
  label: string;
  shortLabel: string;
  hint: string;
  toggleRequired: boolean;
  toggleMultipleFiles: boolean;
  maxFiles: number;
  fileSize: number;
}
export const FILE_ATTACHMENT_FORM_CONFIG: DynamicFormConfig<FileAttachmentFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  toggleMultipleFiles: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  maxFiles: {
    validators: [
      Validators.required,
      MetricStructurePropertiesValidators.isNumber,
      Validators.min(1),
      Validators.max(50),
    ],
  },
  fileSize: {
    validators: [MetricStructurePropertiesValidators.isNumber],
  },
};

export interface FileAttachmentFieldV2FormModel {
  label: string;
  shortLabel: string;
  hint: string;
  toggleRequired: boolean;
  toggleMultipleFiles: boolean;
  maxFiles: number;
  fileSize: number;
  displayUrl: boolean;
  urlRequired: boolean;
  displayPageNumber: boolean;
  pageNumberRequired: boolean;
  displayFileExplanation: boolean;
  explanationFileRequired: boolean;
  explanationFileLabel: string;
  attachmentOptional: boolean;
  attachmentEnabled: boolean;
}

export const FILE_ATTACHMENT_FORM_V2_CONFIG: DynamicFormConfig<FileAttachmentFieldV2FormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  toggleMultipleFiles: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  maxFiles: {
    validators: [
      Validators.required,
      MetricStructurePropertiesValidators.isNumber,
      Validators.min(1),
      Validators.max(50),
    ],
  },
  fileSize: {
    validators: [MetricStructurePropertiesValidators.isNumber],
  },
  displayUrl: {},
  urlRequired: {},
  displayPageNumber: {},
  pageNumberRequired: {},
  displayFileExplanation: {},
  explanationFileRequired: {},
  explanationFileLabel: {},
  attachmentOptional: {},
  attachmentEnabled: {},
};

export interface LinkedDocumentFieldFormModel {
  document: Doc;
  displayName: string;
  toggleNewLine: boolean;
}
export const LINKED_DOCUMENT_FORM_CONFIG: DynamicFormConfig<LinkedDocumentFieldFormModel> = {
  document: {
    validators: [Validators.required],
  },
  displayName: {},
  toggleNewLine: {},
};

export interface ChoiceFieldFormModel {
  label: string;
  shortLabel: string;
  toggleRequired: boolean;
  hint: string;
  widgetType: ChoiceFieldWidgetType;
  optionList: string;
  choiceList: string;
  choiceListApplyAll: boolean;
  choiceListCategory: string;
  choiceListSource: string;
  displayExplanation: boolean;
  explanationLabel: string;
  explanationRequired: boolean;
  multiChoices: boolean;
  allowAddOption: boolean;
  displaySize: ValueDefinitionSize;
  toggleNewLine: boolean;
}

export const CHOICE_FORM_CONFIG: DynamicFormConfig<ChoiceFieldFormModel> = {
  label: {
    validators: [Validators.required, ValidatorsUtils.isNotOnlySpaces],
  },
  shortLabel: {
    validators: [ValidatorsUtils.isNotOnlySpaces, Validators.maxLength(70)],
  },
  toggleRequired: {},
  hint: {
    validators: [Validators.maxLength(50)],
  },
  optionList: {},
  choiceList: {
    validators: [Validators.required],
  },
  choiceListApplyAll: {
    validators: [Validators.required],
  },
  choiceListCategory: {
    validators: [Validators.required],
  },
  choiceListSource: {},
  widgetType: {
    validators: [Validators.required],
  },
  displayExplanation: {
    validators: [Validators.required],
  },
  explanationLabel: {
    validators: [Validators.required],
  },
  explanationRequired: {
    validators: [Validators.required],
  },
  allowAddOption: {
    validators: [Validators.required],
  },
  displaySize: {
    validators: [Validators.required],
  },
  toggleNewLine: {},
};

export type MetricStructureFieldFormModel = InstructionFieldFormModel &
  SubtitleFieldFormModel &
  RichTextFieldFormModel &
  TextFieldFormModel &
  ChoiceFieldFormModel &
  DateFieldFormModel &
  NumberFieldFormModel &
  CalculatedFieldFormModel &
  LinkedDocumentFieldFormModel &
  FileAttachmentFieldFormModel &
  FileAttachmentFieldV2FormModel &
  BooleanFieldFormModel;
