<ng-container *ngIf="menuItems.length">
  <div class="fx-row align-center">
    <button lib-button class="p-0 mr-1" [buttonType]="'inherit'" (click)="action.emit(menuItems[0])">
      {{ menuItems[0].label }}
    </button>

    <lib-popup-menu
      *ngIf="menuItems.length > 1"
      class="popup-menu"
      [icon]="'chevron-down'"
      [menuItems]="menuItems.slice(1)"
      [label]="'+' + (menuItems.length - 1)"
      (action)="action.emit($event)"
    ></lib-popup-menu>
  </div>
</ng-container>
