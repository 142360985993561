import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadHandlerEvent } from 'primeng/fileupload';

@Component({
  selector: 'lib-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss',
})
export class FileUploadComponent {
  @Input() label: string = 'Upload File';
  @Input() accept: string = '*';
  @Input() url: string | undefined = undefined;
  @Input() method: 'post' | 'put' | undefined = undefined;
  @Input() multiple: boolean = false;
  @Input() disabled: boolean = false;
  @Input() autoUpload: boolean = false;
  @Input() maxFileSize: number = 5242880; // 5MB;
  @Input() maxFiles: number | undefined = undefined;
  @Input() showUploadButton: boolean = false;
  @Input() showCancelButton: boolean = false;
  @Input() chooseIcon: string | undefined = undefined;

  @Output() uploadHandler: EventEmitter<FileUploadHandlerEvent> = new EventEmitter<FileUploadHandlerEvent>();
}
