import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

import { interval, Subscription } from 'rxjs';
import Quill from 'quill';

import { SanitizeHtmlPipe } from '../../../pipes';
import { ValidationMessageService } from '../../../services/common';
import { ValueDefinitionSize } from '../../../models';
import { StringUtils } from '../../../classes';
import { Editor } from 'primeng/editor';

let nextId = 0;

@Component({
  selector: 'lib-rich-text-editor-input',
  templateUrl: './rich-text-editor-input.component.html',
  styleUrls: ['./rich-text-editor-input.component.scss'],
  providers: [SanitizeHtmlPipe],
})
export class RichTextEditorInputComponent implements OnInit, OnDestroy {
  @Input() autofocus: boolean = false;
  @Input() autoSaveInterval?: number;
  @Input() control?: FormControl<string | null>;
  @Input() hint?: string;
  @Input() label: string = '';
  @Input() messages?: ValidationErrors;
  @Input() placeholder?: string;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() fieldInfo?: string;

  // Reflecting the native blur event
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<void>();

  @ViewChild('pEditor')
  public editor?: Editor;

  public readonly _inputId = `rich-text-input-${nextId++}`;
  public errorMessages: ValidationErrors = {};
  public tempControl = new FormControl<string>('');

  private autoSaveSubscription?: Subscription;
  private valueChangesSubscription?: Subscription;

  constructor(
    private readonly sanitizeHtml: SanitizeHtmlPipe,
    private readonly validationMessageService: ValidationMessageService,
  ) {}

  public ngOnInit(): void {
    this.tempControl.setValue(this.control?.value ?? '');
    this.errorMessages = { ...this.validationMessageService.validationMessages, ...this.messages };
    this.valueChangesSubscription = this.control?.valueChanges.subscribe((updatedControlValue: string | null) => {
      this.tempControl.setValue(updatedControlValue);
    });
  }

  public ngOnDestroy(): void {
    this.onBlur();
    this.valueChangesSubscription?.unsubscribe();
    this.editor?.getQuill()?.editor.scroll.domNode.removeEventListener('focus', this.onFocus);
    this.editor?.getQuill()?.editor.scroll.domNode.removeEventListener('blur', this.onBlur);
  }

  public onEditorInit(event: { editor: Quill }): void {
    this.editor?.getQuill().editor.scroll.domNode.addEventListener('focus', this.onFocus);
    this.editor?.getQuill().editor.scroll.domNode.addEventListener('blur', this.onBlur);

    if (this.autofocus) {
      event.editor.focus();
    }
  }

  public onBlur = (): void => {
    this.updateControlValue();
    this.autoSaveSubscription?.unsubscribe();
  };

  public onFocus = (): void => {
    if (this.autoSaveInterval) {
      this.autoSaveSubscription = interval(this.autoSaveInterval).subscribe(() => {
        this.updateControlValue();
      });
    }
  };

  private updateControlValue(): void {
    if (this.control?.value !== this.tempControl.value) {
      this.control?.setValue(this.formatValue(this.tempControl.value));
      this.control?.markAsDirty();
      this.control?.markAsTouched();
    }
    this.blur.emit();
  }

  private formatValue(value?: string | null): string | null {
    return StringUtils.stripTagsAndNewlines(value || '') ? this.sanitizeHtml.transform(value) : null;
  }
}
