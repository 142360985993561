import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DecimalMarker, NumberMaskConfig } from './number-mask';
import { getUserLocale } from 'get-user-locale';
import { DecimalPipe, getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { StripNonPrintableCharactersService } from '../services/common';
import isNil from 'lodash/isNil';

@Injectable({
  providedIn: 'root',
})
export class NumberMaskService {
  constructor(
    private decimalPipe: DecimalPipe,
    private stripNonPrintableCharacters: StripNonPrintableCharactersService,
  ) {}

  readonly DEFAULT_LOCALE = 'en-US';

  private userLocale: string = this.getCurrentUserLocale();

  private currentLocaleConfig: NumberMaskConfig = {
    thousandSeparator: this.getGroupSeparator(),
    decimalMarker: this.getDecimalSeparator(),
  };

  private _numberMaskConfig: BehaviorSubject<NumberMaskConfig> = new BehaviorSubject<NumberMaskConfig>(
    this.currentLocaleConfig,
  );

  private countDecimalsOfANumber(number: number) {
    const converted = number.toString();
    if (converted.includes('.')) {
      return converted.split('.')[1].length;
    }
    return 0;
  }

  public readonly numberMaskConfig$: Observable<NumberMaskConfig> = this._numberMaskConfig.asObservable();

  public setUserLocaleAndUpdateMaskConfig(locale: string): void {
    this.userLocale = locale;
    this.currentLocaleConfig = {
      thousandSeparator: this.getGroupSeparator(),
      decimalMarker: this.getDecimalSeparator(),
    };
    this.setMaskConfig(this.currentLocaleConfig);
  }

  public setMaskConfig(numberMaskConfig: NumberMaskConfig): void {
    return this._numberMaskConfig.next(numberMaskConfig);
  }

  public getCurrentUserLocale(): string {
    return getUserLocale({ fallbackLocale: this.DEFAULT_LOCALE }) || this.DEFAULT_LOCALE;
  }

  public getGroupSeparator(locale: string = this.userLocale): string {
    let groupSeparator: string;
    try {
      groupSeparator = getLocaleNumberSymbol(locale, NumberSymbol.CurrencyGroup);
    } catch (error) {
      groupSeparator = getLocaleNumberSymbol(this.DEFAULT_LOCALE, NumberSymbol.CurrencyGroup);
    }
    return this.stripNonPrintableCharacters.sanitizeWhitespaces(groupSeparator);
  }

  public getDecimalSeparator(locale: string = this.userLocale): DecimalMarker {
    try {
      return getLocaleNumberSymbol(locale, NumberSymbol.CurrencyDecimal) as DecimalMarker;
    } catch (error) {
      return getLocaleNumberSymbol(this.DEFAULT_LOCALE, NumberSymbol.CurrencyDecimal) as DecimalMarker;
    }
  }

  public maskCurrency(value?: number, unit?: string | null): string {
    return this.maskNumber(value, 2, unit, true, true);
  }

  public maskNumberNoSymbol(value?: number, maxNumberDecimal?: number): string {
    return this.maskNumber(value, maxNumberDecimal, '', true, false);
  }

  public maskNumber(
    value?: number,
    maxFractionDigits?: number,
    unit?: string | null,
    padding: boolean = false,
    invertSymbol: boolean = false,
    unitSpace: boolean = false,
    withSymbol: boolean = true,
    withLargeNumberAbbreviations: boolean = false,
  ): string {
    if (isNil(value)) {
      return '';
    }

    let _abbreviation: string | undefined;
    let _minFractionDigits: number = this.countDecimalsOfANumber(value);
    let _maxFractionDigits = maxFractionDigits ?? _minFractionDigits;

    if (padding || _minFractionDigits > _maxFractionDigits) {
      _minFractionDigits = _maxFractionDigits;
    }

    if (withLargeNumberAbbreviations) {
      const { scaledValue, abbreviation, minFractionDigits } = this.getValueRoundedWithLargeNumberAbbreviations(value);
      value = scaledValue;
      _abbreviation = abbreviation;
      if (minFractionDigits) {
        _minFractionDigits = minFractionDigits;
        _maxFractionDigits = minFractionDigits;
      }
    }

    const pipedNumber =
      this.decimalPipe.transform(value, `1.${_minFractionDigits}-${_maxFractionDigits}`, this.userLocale) || '0';
    let number = pipedNumber;

    if (_abbreviation) {
      number = `${pipedNumber}${_abbreviation}`;
    }

    if (withSymbol) {
      const unitSymbol = unit ?? '';
      number = invertSymbol ? `${unitSymbol}${number}` : `${number}${unitSpace ? ' ' : ''}${unitSymbol}`;
    }

    return this.stripNonPrintableCharacters.sanitizeWhitespaces(number);
  }

  private getValueRoundedWithLargeNumberAbbreviations(value: number): {
    scaledValue: number;
    minFractionDigits?: number;
    abbreviation?: string;
  } {
    const absValue: number = Math.abs(value);
    const absScaledValue: number = absValue / 1000000;

    if (absValue <= 999999.99) {
      return { scaledValue: value };
    } else if (absScaledValue <= 999.9999999) {
      return { scaledValue: value / 1000000, abbreviation: 'M', minFractionDigits: 1 };
    } else {
      return { scaledValue: value / 1000000000, abbreviation: 'B', minFractionDigits: 1 };
    }
  }
}
