<lib-metric-editor-frequency-field
  data-testid="frequency-numeric-input"
  [control]="valueFormControl"
  [frequenciesControl]="frequenciesControls"
  [sourceConfiguration]="sourceConfiguration"
  [valueGroup]="valueGroup"
  [valueGroupFormGroup]="valueGroupFormGroup"
  [fiscalYearPeriod]="fiscalYearPeriod"
  [collaboratorResponsibility]="collaboratorResponsibility"
  [dataRequestSourceStatus]="dataRequestSourceStatus"
  [displayFieldActions]="displayFieldActions"
  [valueGroupSetStatus]="valueGroupSetStatus"
></lib-metric-editor-frequency-field>
