import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { filter, map, startWith, Subject, takeUntil } from 'rxjs';
import { ChoiceTypeDetails, ChoiceValue, isChoiceValue, OptionListItem } from '../../../../models';
import { ValueFormControl } from '../../../models/valueFormControl';
import cloneDeep from 'lodash/cloneDeep';
import { ValueDefinitionConstants } from '../../../../constants';

interface ChoiceFormGroupOptions {
  multiChoice: boolean;
  displayExplanation?: boolean;
  explanationRequired?: boolean;
}

interface ChoiceOptionUpdate {
  added: string[];
  removed: string[];
}

export class ChoiceFormGroup extends UntypedFormGroup {
  private readonly DEFAULT_CHOICE_OPTION_UPDATE: ChoiceOptionUpdate = {
    added: [],
    removed: [],
  };

  public readonly valueFormControl: ValueFormControl<ChoiceTypeDetails>;
  private vfcValueChange$: Subject<ChoiceValue | null> = new Subject<ChoiceValue | null>();
  private vfcDisabledChange$: Subject<boolean> = new Subject<boolean>();
  private destroy$ = new Subject<void>();
  private opts: ChoiceFormGroupOptions;
  private oldTouchedStatus: boolean = false;
  public readonly valuesControl: UntypedFormControl;
  public additionalTextControl: UntypedFormControl | undefined;
  private latestValueFormControlsValues: string[] = [];
  private currentChoicesUpdate: ChoiceOptionUpdate = cloneDeep(this.DEFAULT_CHOICE_OPTION_UPDATE);

  private get choiceValuesArray(): string[] {
    if (
      this.valuesControl.value &&
      (typeof this.valuesControl.value === 'string' || Array.isArray(this.valuesControl.value))
    ) {
      return Array<string>().concat(this.valuesControl.value);
    } else {
      return [];
    }
  }

  constructor(
    valueFormControl: ValueFormControl<ChoiceTypeDetails>,
    opts: ChoiceFormGroupOptions,
    private optionListItems: OptionListItem[],
    readonly fb: UntypedFormBuilder = new UntypedFormBuilder(),
  ) {
    const valuesControl = fb.control(opts.multiChoice ? [] : '', {
      updateOn: 'change',
      validators: valueFormControl.validator,
    });
    super({ values: valuesControl });
    this.valueFormControl = valueFormControl;
    this.valuesControl = valuesControl;
    this.opts = opts;
    this.setValueFormControlSubscriptions();
    this.setChoiceValueFormSubscriptions();
  }

  public destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // TODO: Replace with subscription when this is merged :
  // https://github.com/angular/angular/issues/10887
  public syncTouchedStatus(): void {
    if (this.oldTouchedStatus !== this.valueFormControl.touched) {
      this.valueFormControl.touched ? this.markAllAsTouched() : this.markAsUntouched();
      this.oldTouchedStatus = this.valueFormControl.touched;
    } else if (this.valuesControl.touched || this.additionalTextControl?.touched) {
      this.valueFormControl.markAsTouched();
      this.oldTouchedStatus = true;
    }
  }

  public blurAdditionalTextControl(): void {
    this.updateValueFormControl();
  }

  private setValueFormControlSubscriptions(): void {
    this.valueFormControl.registerOnChange((value: ChoiceValue | null) => {
      this.vfcValueChange$.next(value);
    });
    this.valueFormControl.registerOnDisabledChange((isDisabled: boolean) => {
      this.vfcDisabledChange$.next(isDisabled);
    });

    this.vfcValueChange$
      .pipe(
        startWith(this.valueFormControl.value),
        map((newValue) => this.choiceValueFromValue(newValue)),
        filter((newValue) => !isEqual(this.toModel(), newValue)),
        takeUntil(this.destroy$),
      )
      .subscribe((newValue) => {
        this.latestValueFormControlsValues = newValue.values;
        this.setChoiceValueControls(newValue);
      });

    this.vfcDisabledChange$
      .pipe(startWith(this.valueFormControl.disabled), takeUntil(this.destroy$))
      .subscribe((isDisabled) => this.handleDisabledChange(isDisabled));
  }

  private choiceValueFromValue(newValue: unknown): ChoiceValue {
    return isChoiceValue(newValue)
      ? newValue
      : {
          values: [],
          ...(this.opts.displayExplanation && this.additionalTextControl && { additional_text: '' }),
        };
  }

  private setChoiceValueControls(vfcValue: ChoiceValue): void {
    this.setChoiceFormControlValue(vfcValue.values);
    this.handleTextFormControl(vfcValue.additional_text ?? '');
    this.setTextFormControlValue(vfcValue.additional_text ?? '');
  }

  private setChoiceFormControlValue(choices: string[]): void {
    let updatedChoices: string | string[] = [];
    // TODO remove this when doing NF-9261 https://novisto.atlassian.net/browse/NF-9261
    if (choices.length > 0) {
      updatedChoices = this.removeCurrentlyUpdatingChoicesFromValues(choices);
    }

    this.valuesControl.setValue(updatedChoices, { emitEvent: false });
  }

  private setTextFormControlValue(text: string): void {
    if (this.additionalTextControl) {
      this.additionalTextControl.setValue(text, { emitEvent: false });
    }
  }

  private setChoiceValueFormSubscriptions(): void {
    this.valuesControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((choiceValue: string | string[]) => {
      const additionalText: string = this.additionalTextControl?.value ?? '';

      if (!this.valueFormControl.valueRef.type_details.selection_set_apply_all) {
        const values = Array.isArray(choiceValue) ? choiceValue : [choiceValue];
        const displayExplanationItems = this.optionListItems.filter((i) => i.display_explanation) || [];
        const explanationRequiredItems = this.optionListItems.filter((i) => i.explanation_required) || [];
        this.opts.explanationRequired = explanationRequiredItems.some((i) => values.includes(i.name));
        this.opts.displayExplanation = displayExplanationItems.some((i) => values.includes(i.name));
        this.removeAdditionalTextControl();
      }

      this.handleTextFormControl(additionalText);
      this.updateValueFormControl(true);
    });

    this.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((choiceFormGroupStatus) => {
      if (choiceFormGroupStatus !== this.valueFormControl.status) {
        if (this.additionalTextControl?.hasError('required') || this.valuesControl.hasError('required')) {
          this.valueFormControl.setErrors({ required: true });
        }
        if (this.additionalTextControl?.hasError('max_length')) {
          this.valueFormControl.setErrors({ maxLength: true });
        }
      }
    });
  }

  private newTextControl(textValue: string = ''): UntypedFormControl {
    const validators: ValidatorFn[] = [Validators.maxLength(ValueDefinitionConstants.DEFAULT_EXPLANATION_MAX_LENGTH)];
    if (this.opts.explanationRequired) {
      validators.push(Validators.required);
    }
    return this.fb.control(textValue, { validators });
  }

  private handleDisabledChange(isDisabled: boolean): void {
    isDisabled ? this.disable({ emitEvent: false }) : this.enable({ emitEvent: false });
  }

  private toModel(): ChoiceValue {
    return {
      values: this.choiceValuesArray,
      ...(this.additionalTextControl && { additional_text: String(this.additionalTextControl.value) }),
    };
  }

  private checkUpdatedChoiceOptions(): void {
    this.currentChoicesUpdate = cloneDeep(this.DEFAULT_CHOICE_OPTION_UPDATE);
    for (const option of (this.valuesControl.value ?? []) as string[]) {
      if (!this.latestValueFormControlsValues.includes(option)) {
        this.currentChoicesUpdate.added.push(option);
      }
    }
    for (const option of this.latestValueFormControlsValues) {
      if (!((this.valuesControl.value ?? []) as string[]).includes(option)) {
        this.currentChoicesUpdate.removed.push(option);
      }
    }
  }

  private removeCurrentlyUpdatingChoicesFromValues(choices: string[]): string[] | string {
    let updatedChoices: string[] | string = [];
    if (this.opts.multiChoice) {
      for (const choice of choices) {
        if (!this.currentChoicesUpdate.removed.includes(choice)) {
          updatedChoices.push(choice);
        }
      }
      for (const addedChoice of this.currentChoicesUpdate.added) {
        if (!choices.includes(addedChoice)) {
          updatedChoices.push(addedChoice);
        }
      }
    } else {
      updatedChoices = choices[0] || '';
    }

    return updatedChoices;
  }

  private newAdditionalTextControl(textValue: string = ''): UntypedFormControl {
    const validators: ValidatorFn[] = [Validators.maxLength(ValueDefinitionConstants.DEFAULT_EXPLANATION_MAX_LENGTH)];
    return this.fb.control(textValue, { validators });
  }

  public handleTextFormControl(text: string): void {
    if (!this.additionalTextControl) {
      if (this.opts.displayExplanation && this.choiceValuesArray.length) {
        this.additionalTextControl = this.newTextControl(text);
        this.addControl('additional_text', this.additionalTextControl);
      }
    } else {
      if (!this.choiceValuesArray.length) {
        this.additionalTextControl = undefined;
        this.removeControl('additional_text', { emitEvent: false });
      }
    }
  }

  public updateValueFormControl(isValues: boolean = false): void {
    this.updateValueAndValidity({ emitEvent: false });
    if (this.valid && (this.additionalTextControl?.dirty || isValues)) {
      this.checkUpdatedChoiceOptions();
      this.valueFormControl.setValue(this.toModel());
    }
  }

  public removeAdditionalTextControl(): void {
    this.additionalTextControl = undefined;
    this.removeControl('additional_text', { emitEvent: true });
  }

  public addAdditionalTextControl(text: string): void {
    this.additionalTextControl = this.newAdditionalTextControl(text);
    if (this.contains('additional_text')) {
      this.removeControl('additional_text');
    }
    this.addControl('additional_text', this.additionalTextControl);
  }
}
