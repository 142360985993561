/**
 * Returns the smaller number.
 */
export function min(a, b) {
  return a < b ? a : b;
}
/**
 * Returns the larger number.
 */
export function max(a, b) {
  return a > b ? a : b;
}