import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  EmptyResults,
  Metric,
  MetricCategory,
  MetricTableDefinition,
  ValueDefinition,
  ValueDefinitionGroup,
} from '../../../models';
import { delay, take } from 'rxjs/operators';
import { MetricStructureSelectable, MetricTableGroup, ValueDefinitionGroupOrMetricTableGroup } from '../../models';
import { MetricStructureStateService } from '../../services/metric-structure-state.service';
import { Observable } from 'rxjs';
import { TranslateService } from '../../../services/common';
import { MetricTableUtils, MetricUtils } from '../../../classes';
import { findLastIndex } from 'lodash';
import { MetricApiService } from '../../../services/types';
import { FeatureFlagService } from '../../../feature-flag';

@Component({
  selector: 'lib-metric-structure-form-panel',
  templateUrl: './metric-structure-form-panel.component.html',
  styleUrls: ['./metric-structure-form-panel.component.scss'],
})
export class MetricStructureFormPanelComponent implements OnInit, OnChanges {
  @Input({ required: true }) metric!: Metric;

  selectedItem?: MetricStructureSelectable;
  loaded: boolean = false;
  updating$: Observable<boolean> = this.metricStructureService.isMetricUpdating$.pipe(delay(0));
  isCreatingField$: Observable<boolean> = this.metricStructureService.isCreatingField$;
  canEditEveryMetrics: boolean = false;

  showCoreAndCustomizableSections?: boolean;
  tableDefinitions?: MetricTableDefinition[];

  groupsOrTables: ValueDefinitionGroupOrMetricTableGroup[] = [];

  readonly eMetricCategory: typeof MetricCategory = MetricCategory;
  readonly emptyResults: EmptyResults = {
    title: '',
    subtitle: this.translateService.instant('Start by adding a Group or Table from the left panel'),
    image: 'laptop-sad',
  };

  canMoveUpLimit: number = 0;

  get hasNoGroupWithFields(): boolean {
    return this.groupCount === 0;
  }

  get groupCount(): number {
    return !this.metric.value_definition_groups ? 0 : this.metric.value_definition_groups.length;
  }

  constructor(
    private translateService: TranslateService,
    private metricStructureService: MetricStructureStateService,
    private featureFlagService: FeatureFlagService,
    private metricService: MetricApiService,
  ) {}

  ngOnInit(): void {
    this.showCoreAndCustomizableSections = this.isReferenceV2MetricInPlatform();

    this.metricStructureService.selectedItem$.subscribe((res) => {
      this.selectedItem = res;
    });

    this.groupsOrTables = this.mergeGroup(this.metric.value_definition_groups ?? []);

    if (this.showCoreAndCustomizableSections && this.metricHasTable()) {
      this.listMetricTableDefinitions();
    }

    this.loaded = true;

    this.metricStructureService.canEditEveryMetrics$.subscribe((res) => {
      this.canEditEveryMetrics = res;
      this.setLimitOfCanMoveUp(res);
    });

    this.metricStructureService.metricTableUpdated$.subscribe((metricTableUpdated) => {
      if (metricTableUpdated && this.showCoreAndCustomizableSections && this.metricHasTable()) {
        this.listMetricTableDefinitions();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.groupsOrTables = this.mergeGroup(this.metric.value_definition_groups ?? []);

    if (changes.metric.currentValue && this.showCoreAndCustomizableSections && this.metricHasTable()) {
      this.updateTableGroupDefinitions();
    }
  }

  private mergeGroup(valueDefinitionGroups: ValueDefinitionGroup[]): ValueDefinitionGroupOrMetricTableGroup[] {
    const groupingByTableId: Record<string, ValueDefinitionGroup[]> = valueDefinitionGroups
      .filter((vdg) => vdg.table_id != null)
      .reduce(
        (grouping: Record<string, ValueDefinitionGroup[]>, vdg: ValueDefinitionGroup) => ({
          ...grouping,
          [vdg.table_id!]: [...(grouping[vdg.table_id!] ?? []), vdg],
        }),
        {},
      );

    const metricTableGroups: MetricTableGroup[] = Object.keys(groupingByTableId)
      .filter((key) => key != '' && groupingByTableId[key].length)
      .map((key) => MetricTableUtils.mergeTableVDGIntoMetricTableGroup(groupingByTableId[key]) as MetricTableGroup);

    const groupNotInTable = valueDefinitionGroups.filter((vdg) => vdg.table_id == null);

    return [...groupNotInTable, ...metricTableGroups].sort((a, b) => a.position - b.position);
  }

  public onSelectedItemChange(item?: Metric | ValueDefinitionGroup | ValueDefinition): void {
    this.metricStructureService.updateSelectedItem(item);
  }

  trackId(index: number, item: { id?: string }): string | undefined {
    return item.id;
  }

  private setLimitOfCanMoveUp(canEditEveryMetric: boolean): void {
    if (canEditEveryMetric || this.metric.category !== MetricCategory.REFERENCE || !this.metric.reference_v2) {
      return;
    }

    const indexOfLastCoreGroupOrTable = findLastIndex(
      this.groupsOrTables,
      (groupOrTable: ValueDefinitionGroupOrMetricTableGroup) =>
        (groupOrTable.table_id === null
          ? (groupOrTable as ValueDefinitionGroup)
          : (groupOrTable as MetricTableGroup).valueDefinitionGroups[0]
        ).core_value_definition_group_id !== null,
    );

    this.canMoveUpLimit = indexOfLastCoreGroupOrTable !== -1 ? indexOfLastCoreGroupOrTable + 1 : 0;
  }

  private isReferenceV2MetricInPlatform(): boolean {
    return (
      MetricUtils.isRefV2Metric(this.metric) &&
      this.featureFlagService.areAnyFeatureFlagsEnabled(['enable_ref_metrics_v2']) &&
      !this.metricStructureService.isAdmin
    );
  }

  private listMetricTableDefinitions(): void {
    this.metricService
      .listMetricTables(this.metric.id)
      .pipe(take(1))
      .subscribe((result) => {
        this.tableDefinitions = result.data;
        this.updateTableGroupDefinitions();
      });
  }

  private updateTableGroupDefinitions(): void {
    const groupOrTablesCopy: ValueDefinitionGroupOrMetricTableGroup[] = [...this.groupsOrTables];
    this.tableDefinitions?.forEach((table) => {
      const tableGroupIndex: number = groupOrTablesCopy.findIndex((groupOrTable) => groupOrTable.table_id === table.id);
      if (tableGroupIndex > -1) {
        (groupOrTablesCopy[tableGroupIndex] as MetricTableGroup).core_metric_table_definition_id =
          table.core_metric_table_definition_id;
        (groupOrTablesCopy[tableGroupIndex] as MetricTableGroup).ignore_taxonomies = table.ignore_taxonomies;
      }
    });
    this.groupsOrTables = [];
    this.groupsOrTables = groupOrTablesCopy;
  }

  private metricHasTable(): boolean {
    return Boolean(this.groupsOrTables.some((groupOrTable) => groupOrTable.table_id));
  }
}
