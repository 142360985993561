import {
  CollaboratorDataRequestWithSource,
  DataRequestIndicatorLink,
  DataRequestSourceStatus,
  DataRequestToolPanelVisibilityType,
  DataRequestType,
  DataRequestUser,
  DataRequestUserResponsibility,
  FiscalYearPeriod,
  Frequency,
} from '../models';
import { faker } from '@faker-js/faker';
import dayjs from 'dayjs';
import { INPUT_DATE_FORMAT } from './builder-utils';

export class DataRequestWithSourceCollaboratorBuilder {
  private readonly _collaboratorDataRequestWithSource: CollaboratorDataRequestWithSource;

  constructor() {
    const dateInTheFuture: Date = faker.date.future(5);

    this._collaboratorDataRequestWithSource = {
      business_unit_id: 'business unit id',
      data_request_users: [],
      frequency_code: '2022',
      frequency: Frequency.YEARLY,
      indicator_links: [],
      last_updated: new Date(),
      name: 'a name',
      source: 'source',
      started_with_reviewer: false,
      status: DataRequestSourceStatus.NOT_STARTED,
      show_historical_data: true,
      show_recommendations: true,
      show_audit_trail_panel: true,
      show_consolidation_panel: true,
      allow_reviewers_to_edit: true,
      allow_approvers_to_edit: true,
      responsibility: DataRequestUserResponsibility.APPROVER,
      active: true,
      hide_metric_codes: false,
      type: DataRequestType.REGULAR,
      due_date: dayjs(dateInTheFuture).format(INPUT_DATE_FORMAT),
    };
  }

  responsibility(responsibility: DataRequestUserResponsibility): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.responsibility = responsibility;
    return this;
  }

  startedWithReviewer(startedWithReviewer: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.started_with_reviewer = startedWithReviewer;
    return this;
  }

  name(name: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.name = name;
    return this;
  }

  source(source: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.source = source;
    return this;
  }

  status(status: DataRequestSourceStatus): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.status = status;
    return this;
  }

  indicatorLinks(indicatorLinks: DataRequestIndicatorLink[]): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.indicator_links = indicatorLinks;
    return this;
  }

  frequencyCode(frequencyCode: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.frequency_code = frequencyCode;
    return this;
  }

  prefillFrequencyCode(prefillFrequencyCode: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.prefill_frequency = prefillFrequencyCode;
    return this;
  }

  frequency(frequency: Frequency): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.frequency = frequency;
    return this;
  }

  fiscalYearPeriodId(fiscal_year_period_id: string | undefined): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.fiscal_year_period_id = fiscal_year_period_id;
    return this;
  }

  fiscalYearPeriod(fiscal_year_period: FiscalYearPeriod | undefined): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.fiscal_year_period = fiscal_year_period;
    return this;
  }

  businessUnitId(businessUnitId: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.business_unit_id = businessUnitId;
    return this;
  }

  introduction(introduction: string): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.introduction = introduction;
    return this;
  }

  conclusion(conclusion: string | undefined): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.conclusion = conclusion;
    return this;
  }

  dataRequestUsers(dataRequestUsers: DataRequestUser[]): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.data_request_users = dataRequestUsers;
    return this;
  }

  active(active: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.active = active;
    return this;
  }

  allowReviewersToEdit(allowReviewersToEdit: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.allow_reviewers_to_edit = allowReviewersToEdit;
    return this;
  }

  allowApproversToEdit(allowApproversToEdit: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.allow_approvers_to_edit = allowApproversToEdit;
    return this;
  }

  showHistoricalData(showHistoricalData: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.show_historical_data = showHistoricalData;
    return this;
  }

  showConsolidation(showConsolidation: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.show_consolidation_panel = showConsolidation;
    return this;
  }

  showRecommendations(showRecommendations: boolean): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.show_recommendations = showRecommendations;
    return this;
  }

  allowUsersToAttachPublicFiles(
    allowUsersToAttachPublicFiles: DataRequestToolPanelVisibilityType,
  ): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.allow_users_to_attach_public_files = allowUsersToAttachPublicFiles;
    return this;
  }

  allowUsersToAttachPrivateFiles(
    allowUsersToAttachPrivateFiles: DataRequestToolPanelVisibilityType,
  ): DataRequestWithSourceCollaboratorBuilder {
    this._collaboratorDataRequestWithSource.allow_users_to_attach_private_files = allowUsersToAttachPrivateFiles;
    return this;
  }

  build(): CollaboratorDataRequestWithSource {
    return this._collaboratorDataRequestWithSource;
  }
}
