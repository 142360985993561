import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
})
export class StatusChipComponent implements OnInit {
  @Input({ required: true }) status!: string;
  @Input() type?: string;
  @Input() size: 'default' | 'large' = 'default';

  cssClassName: string = '';

  ngOnInit(): void {
    this.cssClassName = this.status;

    if (this.type) {
      this.cssClassName = `${this.status}-${this.type} ${this.status}-${this.type}-accent`;
    }
  }
}
