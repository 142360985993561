import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { combineLatestWith, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { ValidationMessageService } from '../../../services/common';
import { isEqual } from 'lodash';
import { ValueDefinitionSize } from '../../../models';

let nextId = 0;

@Component({
  selector: 'lib-multi-select-chip-input',
  templateUrl: './multi-select-chip-input.component.html',
  styleUrls: ['./multi-select-chip-input.component.scss'],
})
export class MultiSelectChipInputComponent implements OnChanges, OnInit {
  @Input() label = '';
  @Input() hint?: string;
  @Input() control?: UntypedFormControl;
  @Input() messages?: ValidationErrors;
  @Input() set options(options: any[]) {
    this._options = options;
    this.optionsSubject.next(options);
  }
  get options() {
    return this._options;
  }
  @Input() bindLabel?: string | ((option: any) => string);
  @Input() bindValue?: string | ((option: any) => any);
  @Input() compareWith?: string | ((option1: any, option2: any) => boolean);
  @Input() freeSolo = false;
  @Input() forceControlValue = false;
  @Input() readonly: boolean = false;
  @Input() chipMaxLength?: number;
  @Input() size: ValueDefinitionSize = ValueDefinitionSize.large;
  @Input() labelPosition: 'top' | 'left' = 'top';

  @Output() addValue: EventEmitter<MatChipInputEvent> = new EventEmitter<MatChipInputEvent>();
  @Output() filterValueChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  readonly _inputId = `multi-select-chip-input-${nextId++}`;

  private controlValueChange$: Subject<unknown> = new Subject<unknown>();
  availableOptions$: Observable<any[]> = of([]);
  filteredOptions$: Observable<any[]> = of([]);
  inputControl: UntypedFormControl = new UntypedFormControl('');
  required: boolean = false;
  errorMessages: ValidationErrors = {};

  private _options: any[] = [];

  optionsSubject = new BehaviorSubject<unknown[]>([]);

  constructor(private validationMessageService: ValidationMessageService) {}

  ngOnChanges(): void {
    this.initializeInput();
  }

  ngOnInit(): void {
    this.addInputControlValidators();

    this.availableOptions$ = this.controlValueChange$.pipe(
      startWith(this.control?.value as unknown[]),
      combineLatestWith(this.optionsSubject),
      distinctUntilChanged(isEqual),
      debounceTime(100),
      map(() => {
        const selectedOptions = this.selectedOptions;
        return this._options.filter((option) =>
          this.forceControlValue
            ? !selectedOptions.some((selectedValue) => this._compareWith(selectedValue, this.getOptionValue(option)))
            : !selectedOptions.includes(option),
        );
      }),
    );

    this.filteredOptions$ = this.inputControl.valueChanges.pipe(
      startWith(''),
      debounceTime(400),
      map((inputValue) => (typeof inputValue !== 'string' ? '' : inputValue.toLowerCase())),
      switchMap((inputValue: string) =>
        this.availableOptions$.pipe(
          map((options) => options.filter((option) => this.getOptionLabel(option).toLowerCase().includes(inputValue))),
          tap((options) => {
            if (options.length === 0) {
              this.filterValueChanged.emit(inputValue);
            }
          }),
        ),
      ),
    );
  }

  private initializeInput() {
    this.required = this.control?.hasValidator(Validators.required) ?? false;
    this.errorMessages = {
      ...this.validationMessageService.validationMessages,
      ...this.messages,
    };
    this.control?.registerOnChange((value: unknown) => {
      this.controlValueChange$.next(value);
    });
  }

  _compareWith(option1: any, option2: any): boolean {
    if (this.compareWith) {
      if (typeof this.compareWith === 'function') {
        return this.compareWith(option1, option2);
      } else {
        return option1[this.compareWith] === option2[this.compareWith];
      }
    }
    return JSON.stringify(option1) === JSON.stringify(option2);
  }

  get selectedOptions(): any[] {
    if (this.control?.value != null) {
      if (this.forceControlValue) {
        return this.control.value as any[];
      }
      return this._options.filter((option) =>
        (this.control?.value as any[]).some((selectedValue) =>
          this._compareWith(selectedValue, this.getOptionValue(option)),
        ),
      );
    }
    return [];
  }

  getOptionLabel(option: any): string {
    if (typeof this.bindLabel === 'function') {
      return this.bindLabel(option);
    }
    return this.bindLabel ? (option[this.bindLabel] as string) : String(option);
  }

  getOptionValue(option: any): any {
    if (typeof this.bindValue === 'function') {
      return this.bindValue(option);
    }
    return this.bindValue ? option[this.bindValue] : option;
  }

  _addValue(event: MatChipInputEvent): void {
    if (this.inputControl.invalid) {
      return;
    }

    this.clearInput();
    const inputValue = event.value;
    if (inputValue) {
      const existingOption = this.getOptionFromInputValue(inputValue);
      if (existingOption) {
        this.addValueIfNotSelected(this.getOptionValue(existingOption));
      } else if (this.freeSolo) {
        this.addValue.emit(event);
        this.control?.markAsTouched();
        this.control?.markAsDirty();
      }
    }
  }

  removeValue(value: any): void {
    const updatedValues = (this.control?.value as any[] | null)?.filter((v) => !this._compareWith(v, value)) ?? [];
    this.control?.setValue(updatedValues.length > 0 ? updatedValues : null);
    this.control?.markAsTouched();
    this.control?.markAsDirty();
  }

  selectOption(event: MatAutocompleteSelectedEvent): void {
    this.clearInput();
    this.addValueIfNotSelected(event.option.value);
  }

  public setFocus(): void {
    this.input.nativeElement.focus();
  }

  private getOptionFromInputValue(value: string): any {
    return this._options.find((option) => this.getOptionLabel(option).toLowerCase() === value.toLowerCase());
  }

  private clearInput() {
    this.inputControl.setValue('');
    this.input.nativeElement.value = '';
  }

  private addValueIfNotSelected(value: any): void {
    const valueAlreadySelected = (this.control?.value as any[] | null)?.includes(value) ?? false;
    if (!valueAlreadySelected) {
      this.control?.setValue([...((this.control?.value as any[] | null) ?? []), value]);
      this.control?.markAsTouched();
      this.control?.markAsDirty();
    }
  }

  private addInputControlValidators(): void {
    if (this.chipMaxLength && this.freeSolo) {
      this.inputControl.addValidators(Validators.maxLength(this.chipMaxLength));
    }
  }
}
