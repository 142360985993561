import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DocumentHostEnv, FileDocumentInterface } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export abstract class BaseMetricStructureService {
  public readonly metricTablePublished$ = new Subject<string | null>();

  abstract documentLinkHostEnvironment: DocumentHostEnv;

  abstract getCoreDocumentMetadata(docId: string): Observable<FileDocumentInterface>;
  abstract getCoreDocument(docStorageFilename: string): Observable<Blob>;
}
