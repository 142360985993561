import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject } from '@angular/core';
import { TranslateCompiler } from '@ngx-translate/core';
import MessageFormat from '@messageformat/core';
const MESSAGE_FORMAT_CONFIG = new InjectionToken("MESSAGE_FORMAT_CONFIG");
const defaultConfig = {
  biDiSupport: false,
  formatters: {},
  strictNumberSign: false,
  currency: "USD",
  strictPluralKeys: true
};

/**
 * This compiler expects ICU syntax and compiles the expressions with messageformat.js
 */
class TranslateMessageFormatCompiler extends TranslateCompiler {
  constructor(config) {
    super();
    this.mfCache = new Map();
    const {
      formatters: customFormatters,
      biDiSupport,
      strictNumberSign: strict,
      currency,
      strictPluralKeys
    } = {
      ...defaultConfig,
      ...config
    };
    this.config = {
      customFormatters,
      biDiSupport,
      strict,
      currency,
      strictPluralKeys
    };
  }
  compile(value, lang) {
    return this.getMessageFormatInstance(lang).compile(value);
  }
  compileTranslations(translations, lang) {
    if (typeof translations === "string") {
      return this.compile(translations, lang);
    }
    return Object.keys(translations).reduce((acc, key) => {
      const value = translations[key];
      acc[key] = this.compileTranslations(value, lang);
      return acc;
    }, {});
  }
  getMessageFormatInstance(locale) {
    if (!this.mfCache.has(locale)) {
      this.mfCache.set(locale, new MessageFormat(locale, this.config));
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.mfCache.get(locale);
  }
  static {
    this.ɵfac = function TranslateMessageFormatCompiler_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TranslateMessageFormatCompiler)(i0.ɵɵinject(MESSAGE_FORMAT_CONFIG, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslateMessageFormatCompiler,
      factory: TranslateMessageFormatCompiler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateMessageFormatCompiler, [{
    type: Injectable
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [MESSAGE_FORMAT_CONFIG]
      }]
    }];
  }, null);
})();

/* eslint-disable-next-line  no-console */
const log = (...message) => console.log(tag, ...message);
const tag = "[TranslateMessageFormatCompiler]";
class TranslateMessageFormatDebugCompiler extends TranslateMessageFormatCompiler {
  compile(value, lang) {
    log(`COMPILE (${lang})`, value);
    const interpolationFn = super.compile(value, lang);
    return this.wrap(interpolationFn, value);
  }
  compileTranslations(value, lang) {
    log(`COMPILE (${lang})`, value);
    return super.compileTranslations(value, lang);
  }
  wrap(fn, reference) {
    return params => {
      log("INTERPOLATE", reference, params);
      return fn(params);
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTranslateMessageFormatDebugCompiler_BaseFactory;
      return function TranslateMessageFormatDebugCompiler_Factory(__ngFactoryType__) {
        return (ɵTranslateMessageFormatDebugCompiler_BaseFactory || (ɵTranslateMessageFormatDebugCompiler_BaseFactory = i0.ɵɵgetInheritedFactory(TranslateMessageFormatDebugCompiler)))(__ngFactoryType__ || TranslateMessageFormatDebugCompiler);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TranslateMessageFormatDebugCompiler,
      factory: TranslateMessageFormatDebugCompiler.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslateMessageFormatDebugCompiler, [{
    type: Injectable
  }], null, null);
})();

/*
 * Public API Surface of ngx-translate-messageformat-compiler
 */

/**
 * Generated bundle index. Do not edit.
 */

export { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler, TranslateMessageFormatDebugCompiler, defaultConfig };
