<div
  *ngIf="control"
  libFormField
  [size]="size"
  [required]="required"
  [control]="control"
  [labelPosition]="labelPosition"
  [dataTestId]="'cascade-select-input'"
  [class]="'p-field'"
>
  <lib-form-field-label [label]="label" [control]="control" [id]="_labelId"></lib-form-field-label>

  <!-- There is a bug in this PrimeNG component, options is typed wrongly as string[] when it should receive an array of objects -->
  <p-cascadeSelect
    [ariaLabelledBy]="_labelId"
    [options]="$any(options)"
    [optionLabel]="optionLabel"
    [optionGroupLabel]="groupLabel"
    [optionGroupChildren]="optionGroupChildren"
    [formControl]="control"
    [id]="_labelId"
    [showClear]="showClear"
    [required]="required"
    [appendTo]="'body'"
    [ngClass]="{ invalid: control.invalid && (control.dirty || control.touched), disabled: control.disabled }"
    (onHide)="setToTouched()"
  >
    <ng-template pTemplate="value" let-value>
      <span *ngIf="value" class="input-text">{{ prefix ?? "" }} {{ value[optionLabel] }}</span>
    </ng-template>
  </p-cascadeSelect>

  <div class="subscript-wrapper" *ngIf="!isFilter" data-testid="subscript-wrapper">
    <ng-container *ngIf="(hint && control.valid) || control.untouched">
      <p class="message">
        {{ hint }}
      </p>
    </ng-container>

    <ng-container *ngIf="control.dirty || control.touched">
      <p *ngFor="let error of control.errors | errorKeys" id="error-message" class="p-error message">
        {{ errorMessages[error] }}
      </p>
    </ng-container>
  </div>
</div>
