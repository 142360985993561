<mat-menu #errorDetailsMenu="matMenu" yPosition="above">
  <ng-template matMenuContent>
    <div class="p-2">
      {{ calculationErrorMsg }}
    </div>
  </ng-template>
</mat-menu>

<div
  class="numeric-input-container"
  [title]="this.readonly ? (controlNumberDisplay.value | numberMask: maxDecimals) : ''"
>
  <div
    class="error-menu-trigger"
    #errorDetailsMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="errorDetailsMenu"
  ></div>

  <div
    *ngIf="control"
    libFormField
    [size]="size"
    [required]="required"
    [control]="control"
    [readonly]="readonly"
    [labelPosition]="labelPosition"
    [dataTestId]="'numeric-field'"
  >
    <ng-container
      *ngIf="numberMaskConfig$ | async as numberMaskConfig"
      [ngSwitch]="control | numericInputType: control.disabled || this.readonly"
    >
      <lib-form-field-label
        [sameSizeLabels]="sameSizeLabels"
        [label]="label"
        [control]="control"
        [for]="_inputId"
        [displayReset]="!isCalculated"
        [fieldInfo]="fieldInfo"
        [isBypassConsolidation]="isBypassConsolidation"
      ></lib-form-field-label>
      <mat-form-field [ngClass]="{ 'maximum-field-width': true, 'input-with-left-label': labelPosition === 'left' }">
        <input
          *ngSwitchCase="eNumericInputType.notApplicable"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [readonly]="control.disabled || this.readonly"
          data-testid="numeric-field-input-not-applicable"
        />
        <input
          *ngSwitchCase="eNumericInputType.readonly"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="controlNumberDisplay"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [placeholder]="placeholder"
          [readonly]="true"
          [mask]="maskNumberDisplay"
          [thousandSeparator]="numberMaskConfig.thousandSeparator"
          [decimalMarker]="numberMaskConfig.decimalMarker"
          [allowNegativeNumbers]="true"
          [clearIfNotMatch]="false"
          libFocusState
          data-testid="numeric-field-input-display"
        />
        <input
          *ngSwitchCase="eNumericInputType.full"
          #input
          matInput
          class="color-grey-900"
          type="text"
          [formControl]="control"
          [errorStateMatcher]="errorStateMatcher"
          [attr.aria-label]="_inputId"
          [id]="_inputId"
          [placeholder]="placeholder"
          [readonly]="control.disabled || this.readonly"
          [mask]="maskInput"
          [thousandSeparator]="numberMaskConfig.thousandSeparator"
          [decimalMarker]="numberMaskConfig.decimalMarker"
          [allowNegativeNumbers]="true"
          [clearIfNotMatch]="false"
          libFocusState
          data-testid="numeric-field-input"
        />
        <mat-hint *ngIf="hint" class="fx-row justify-space-between w-100">
          <span>{{ hint }}</span>
        </mat-hint>
        <lib-spinner *ngIf="control.status === 'PENDING'" [diameter]="16" matSuffix></lib-spinner>
        <div matSuffix class="color-grey-900 fx-row align-center">
          <span>{{ suffix }}</span>
          <div *ngIf="isCalculated && calculationErrorMsg" class="error-msg-icon">
            <mat-icon
              aria-hidden="false"
              aria-label="errorIcon"
              class="ml-1 color-warning"
              svgIcon="warning"
              (mouseenter)="openErrorDetailsmenu()"
            ></mat-icon>
          </div>
        </div>
        <mat-hint class="mat-error" *ngFor="let errorKey of parentControl?.errors ?? null | errorKeys">{{
          errorMessages[errorKey]
        }}</mat-hint>
        <mat-error *ngFor="let errorKey of control.errors | errorKeys">{{ errorMessages[errorKey] }}</mat-error>
        <mat-error *ngIf="control.errors?.min && !messages.min"
          >{{ "Value must not be below {count}" | translate: { count: control.errors?.min.min } }}
        </mat-error>
        <mat-error *ngIf="control.errors?.max && !messages.max">
          {{ "Value must not be above {count}" | translate: { count: control.errors?.max.max } }}
        </mat-error>
        <mat-error *ngIf="control?.errors?.nonNumericError">
          {{ "Value must be a valid number" | translate }}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>
</div>
