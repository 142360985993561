import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'lib-plus-more-menu',
  templateUrl: './plus-more-menu.component.html',
  styleUrls: ['./plus-more-menu.component.scss'],
})
export class PlusMoreMenuComponent<T extends MenuItem = MenuItem> {
  @Input({ required: true }) menuItems: T[] = [];

  @Output() action = new EventEmitter<T>();
}
