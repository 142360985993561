import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';

import { combineLatestWith, filter, map } from 'rxjs/operators';
import { EMPTY, Observable, switchMap } from 'rxjs';

import {
  ActionItem,
  ApiResponse,
  ChoiceFieldSource,
  Metric,
  MetricCategory,
  OptionList,
  OptionListCategory,
  OptionListType,
  ResourceType,
} from '../../../../../../models';
import { OptionListsApiService } from '../../../../../../services/api-services';
import { ActionItemUtils } from '../../../../../../classes';
import { tapResponse } from '@ngrx/operators';

export interface MetricStructureChoiceFieldSelectionCategoryState {
  categoryId: string | null;
  source?: ChoiceFieldSource | null;
}

export interface MetricStructureChoiceFieldSelectionState {
  optionLists: ActionItem<OptionList>[];
  optionListCategories: ActionItem<OptionListCategory>[];
  optionListCategoryState?: MetricStructureChoiceFieldSelectionCategoryState | null;
}

@Injectable()
export class MetricStructureChoiceFieldSelectionStore extends ComponentStore<MetricStructureChoiceFieldSelectionState> {
  private static readonly DEFAULT_STATE: MetricStructureChoiceFieldSelectionState = {
    optionListCategories: [],
    optionLists: [],
  };

  public readonly state$: Observable<MetricStructureChoiceFieldSelectionState> = this.select((state) => state);
  public readonly optionListCategoryState$: Observable<
    MetricStructureChoiceFieldSelectionCategoryState | null | undefined
  > = this.select((state) => state.optionListCategoryState);

  private optionListType = OptionListType.reference;
  private optionListsEnabled = false;

  constructor(private readonly optionListsApiService: OptionListsApiService) {
    super(MetricStructureChoiceFieldSelectionStore.DEFAULT_STATE);
  }

  public initialize(optionListsEnabled: boolean, metric?: Metric): void {
    this.optionListsEnabled = optionListsEnabled;
    this.optionListType =
      metric?.category === MetricCategory.THIRD_PARTY ? OptionListType.third_party_metrics : OptionListType.reference;

    if (optionListsEnabled) {
      this.optionListsApiService
        .listOptionListCategories()
        .pipe(map((res) => ActionItemUtils.resourcesToActionItem(res.data, ResourceType.option_list_category)))
        .subscribe((optionListCategories) => {
          this.patchState({ optionListCategories });
        });
    } else {
      this.patchState({ optionListCategoryState: null });
    }

    this.fetchOptionLists();
  }

  public readonly updateOptionListCategoryState = this.updater(
    (
      state: MetricStructureChoiceFieldSelectionState,
      optionListCategoryState: MetricStructureChoiceFieldSelectionCategoryState | null,
    ): MetricStructureChoiceFieldSelectionState => ({ ...state, optionListCategoryState }),
  );

  public readonly updateOptionListsState = this.updater(
    (
      state: MetricStructureChoiceFieldSelectionState,
      optionLists: OptionList[],
    ): MetricStructureChoiceFieldSelectionState => ({
      ...state,
      optionLists: ActionItemUtils.resourcesToActionItem(optionLists, ResourceType.selection_set),
    }),
  );

  public readonly fetchOptionLists = this.effect((trigger$) =>
    trigger$.pipe(
      combineLatestWith(this.optionListCategoryState$),
      filter(([_, optionListCategoryState]) =>
        Boolean(
          optionListCategoryState === null ||
            optionListCategoryState?.categoryId ||
            (optionListCategoryState?.categoryId === null &&
              optionListCategoryState?.source === ChoiceFieldSource.core),
        ),
      ),
      switchMap(([_, optionListCategoryState]) => {
        if (!this.optionListsEnabled || optionListCategoryState?.source === ChoiceFieldSource.core) {
          return this.optionListsApiService.listResourceLists(null, null);
        }

        return this.optionListsApiService.listOptionLists({
          core_option_list_category_id: optionListCategoryState?.categoryId || undefined,
          selection_set_category_id: optionListCategoryState?.categoryId || undefined,
          type: this.optionListType,
        });
      }),
      tapResponse(
        (res: ApiResponse<OptionList[]>) => {
          this.updateOptionListsState(res.data);
        },
        (_err) => EMPTY,
      ),
    ),
  );
}
