import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { iif, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, skip, startWith, switchMap, tap } from 'rxjs/operators';

import {
  ConfirmationDialogConfig,
  DataRequestSourceStatus,
  DataRequestUserResponsibility,
  DataRequestValueGroupSetStatus,
  DialogResult,
  FocusedFieldAction,
  Indicator,
  isQuantitativeRule,
  ItemType,
  PlatformValueGroupSetStatus,
  ReportIntegrationType,
  SOURCE_CONFIGURATION,
  Status,
  Value,
  ValueDefinitionDisplayType,
  ValueGroup,
} from '../../../models';
import { TranslateService } from '../../../services/common';
import { ResetValueOptions, ValueFormControl } from '../../models/valueFormControl';
import { UpsertValue } from '../../models/upsertValue';
import { ValueGroupSetForm } from '../../models/valueGroupSetForm';

import { ValueDefinitionService } from '../../services/value-definition.service';
import { ConditionalTriggerService } from '../../services/conditional-trigger.service';

import { MetricEditorTextAreaFieldComponent } from '../metric-editor-text-area-field/metric-editor-text-area-field.component';
import { MetricEditorTextFieldComponent } from '../metric-editor-text-field/metric-editor-text-field.component';
import { MetricEditorRichTextComponent } from '../metric-editor-rich-text/metric-editor-rich-text.component';
import { MetricEditorFileAttachmentComponent } from '../metric-editor-file-attachment/metric-editor-file-attachment.component';
import { MetricEditorBooleanComponent } from '../metric-editor-boolean/metric-editor-boolean.component';
import { MetricEditorDateFieldComponent } from '../metric-editor-date-field/metric-editor-date-field.component';
import { MetricEditorChoiceFieldComponent } from '../metric-editor-choice-field/metric-editor-choice-field.component';
import { MetricEditorNumericFieldComponent } from '../metric-editor-numeric-field/metric-editor-numeric-field.component';
import { DEFAULT_DOCUMENT_CONTEXT, DocumentContext } from '../../models/documentContext';
import isEqual from 'lodash/isEqual';
import { ConfirmationDialogComponent, DialogsService } from '../../../dialogs';
import { debounceTimeIfAny, waitForNextUpdate } from '../../utils/operators';
import { ValueDefinitionDisplayTypeDebounceTime } from '../../models/valueDefinitionDisplayTypeDebounceTime';
import { FormUtils, ObservableUtils } from '../../../classes';
import { BaseMetricEditorFormStateService } from '../../services/base-metric-editor-form-state/base-metric-editor-form-state.service';
import { isNullishValue } from '../../utils/valueUtils';
import { ValueGroupFormGroup } from '../../models/valueGroupFormGroup';

@Component({
  selector: 'lib-metric-editor-field-handler',
  templateUrl: './metric-editor-field-handler.component.html',
  styleUrls: ['./metric-editor-field-handler.component.scss'],
})
export class MetricEditorFieldHandlerComponent implements OnInit, OnDestroy {
  @Input({ required: true }) valueFormControl!: ValueFormControl;
  @Input({ required: true }) valueGroupFormGroup!: ValueGroupFormGroup;
  @Input({ required: true }) sourceConfiguration!: SOURCE_CONFIGURATION;
  @Input({ required: true }) valueGroup!: ValueGroup;
  @Input() indicator?: Indicator;
  @Input() documentContext: DocumentContext = DEFAULT_DOCUMENT_CONTEXT;
  @Input() focusId = '';
  @Input() indicatorId: string = '';
  @Input() vgsetId: string = '';
  @Input() displayFieldActions: boolean = false;
  @Input() collaboratorResponsibility?: DataRequestUserResponsibility;
  @Input() valueGroupSetStatus!: PlatformValueGroupSetStatus | DataRequestValueGroupSetStatus;
  @Input() dataRequestSourceStatus!: DataRequestSourceStatus;
  @Input() integrationType: ReportIntegrationType | null = null;

  @Output() update: EventEmitter<UpsertValue> = new EventEmitter<UpsertValue>();
  @Output() resetValue: EventEmitter<string> = new EventEmitter<string>();
  @Output() metricLinkEdit: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('valueFieldRef') valueFieldRef?:
    | MetricEditorTextAreaFieldComponent
    | MetricEditorTextFieldComponent
    | MetricEditorRichTextComponent
    | MetricEditorFileAttachmentComponent
    | MetricEditorBooleanComponent
    | MetricEditorDateFieldComponent
    | MetricEditorChoiceFieldComponent
    | MetricEditorNumericFieldComponent;

  valueDefinitionDisplayType?: ValueDefinitionDisplayType;
  isFocusable: boolean = false;
  focusedField$: Observable<Value | undefined>;
  focusFieldUniqueId$: Observable<string>;
  isFocusEnabled: boolean = false;

  readonly eValueDefinitionDisplayType = ValueDefinitionDisplayType;
  readonly eItemType = ItemType;

  private debounceTime: number = 0;
  private destroy$ = new Subject<void>();

  private valueChanges$ = new Subject<unknown>();
  private valueChangesSubscription?: Subscription;
  private valueResetsSubscription?: Subscription;

  constructor(
    private translateService: TranslateService,
    private readonly valueDefinitionService: ValueDefinitionService,
    private readonly conditionalTriggerService: ConditionalTriggerService,
    private readonly dialogService: DialogsService,
    private baseMetricEditorFormStateService: BaseMetricEditorFormStateService,
  ) {
    this.focusedField$ = this.baseMetricEditorFormStateService.focusedField$.pipe(takeUntil(this.destroy$));
    this.focusFieldUniqueId$ = this.baseMetricEditorFormStateService.focusFieldUniqueId$.pipe(takeUntil(this.destroy$));
  }

  ngOnInit(): void {
    this.valueDefinitionDisplayType = this.valueDefinitionService.getDisplayType(this.valueFormControl.valueRef);
    this.isFocusable = this.valueDefinitionService.isFocusableDisplayField(this.valueFormControl.valueRef);
    this.isFocusEnabled = this.baseMetricEditorFormStateService.enableFocus;
    this.debounceTime = ValueDefinitionDisplayTypeDebounceTime[this.valueDefinitionDisplayType];
    this.setupHandler();
    this.setupFocusHandler();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.valueChangesSubscription?.unsubscribe();
    this.valueResetsSubscription?.unsubscribe();
  }

  public onBlur(): void {
    this.valueChanges$.next(this.valueFormControl.value);
  }

  private setupHandler() {
    this.setupValueChangesSubscription();
    this.setupFormControlChangesSubscription();
    this.setupValueResetsSubscription();
  }

  private setupFocusHandler() {
    this.valueFormControl.focusValue$
      .pipe(ObservableUtils.filterNullish())
      .pipe(takeUntil(this.destroy$))
      .subscribe((focusValue) => this.selectFieldForFocus(focusValue, this.valueGroup));
  }

  private setupValueChangesSubscription(): void {
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = this.valueChanges$
      .pipe(
        startWith(this.valueFormControl.value),
        distinctUntilChanged(isEqual),
        skip(1),
        debounceTimeIfAny(this.debounceTime),
        waitForNextUpdate(this.valueFormControl),
        mergeMap((value: unknown) =>
          iif(() => !isNullishValue(value), this.handleUpdateValue(value), this.handleImplicitResetValue(value)),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private setupFormControlChangesSubscription(): void {
    if (this.valueDefinitionDisplayType !== ValueDefinitionDisplayType.text_rich) {
      this.valueFormControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe({
        next: (value) => this.valueChanges$.next(value),
      });
    }
  }

  private handleUpdateValue(value: unknown): Observable<boolean> {
    return of(value).pipe(
      filter(() => this.valueFormControl.valid),
      switchMap((value: unknown) =>
        this.confirmPotentialDataLossFromConditionalTriggers().pipe(
          tap((confirmed) => {
            if (confirmed) {
              this.emitUpdate(value);
            } else {
              this.revertValueFormControlToInitialValue();
              this.setupValueChangesSubscription();
            }
          }),
        ),
      ),
    );
  }

  private handleImplicitResetValue(value: unknown): Observable<unknown> {
    return of(value).pipe(tap(() => this.valueFormControl.resetValue({ shouldPrompt: false })));
  }

  private setupValueResetsSubscription(): void {
    this.valueResetsSubscription = this.valueFormControl.valueResets
      .pipe(
        switchMap((options: ResetValueOptions | undefined) =>
          options?.shouldPrompt ? this.promptResetValueConfirmationDialog() : of(true),
        ),
        filter(Boolean),
        tap(() => {
          this.valueFormControl.reset();
        }),
        switchMap(() => this.confirmPotentialDataLossFromConditionalTriggers()),
        tap((confirmed) => {
          if (confirmed) {
            if (!FormUtils.isNullOrEmpty(this.valueFormControl.valueRef.id)) {
              this.resetValue.emit(this.valueFormControl.valueRef.id);
            }
          } else {
            this.revertValueFormControlToInitialValue();
          }
        }),
        tap(() => this.setupValueChangesSubscription()),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  private promptResetValueConfirmationDialog(): Observable<boolean> {
    return this.dialogService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          warningMsg: this.translateService.instant(
            'Are you sure you want to reset the value of this field? This cannot be undone',
          ),
          primaryBtn: this.translateService.instant('Reset'),
        },
      })
      .afterClosed()
      .pipe(map((result: DialogResult | undefined) => result?.status === Status.CONFIRMED));
  }

  private confirmPotentialDataLossFromConditionalTriggers(): Observable<boolean> {
    if (!this.conditionalTriggerService.isSupportedType(this.valueFormControl.valueRef.type)) {
      return of(true);
    }

    const valueGroupSetForm = this.valueFormControl.root as ValueGroupSetForm;
    const untriggeredFilledValues = this.conditionalTriggerService.getUntriggeredFilledValueFormControls(
      this.valueFormControl,
      valueGroupSetForm,
    );

    if (untriggeredFilledValues.length === 0) {
      return of(true);
    }

    const triggerContainsNonQuantitativeValues = untriggeredFilledValues.some(
      (untriggeredFilledValue) => !isQuantitativeRule(untriggeredFilledValue.valueRef.consolidation_rule),
    );

    if (this.valueFormControl.isConsolidated() && !triggerContainsNonQuantitativeValues) {
      return of(true);
    }

    return this.dialogService
      .open<ConfirmationDialogComponent, ConfirmationDialogConfig>(ConfirmationDialogComponent, {
        data: {
          warningMsg:
            'You will lose the data associated with the triggered fields if you change the value of this field.',
          primaryBtn: this.translateService.instant('Confirm'),
        },
      })
      .afterClosed()
      .pipe(
        switchMap((result: DialogResult | undefined) => (result?.status === Status.CONFIRMED ? of(true) : of(false))),
      );
  }

  public emitUpdate(value: unknown): void {
    this.handlePendingCreation();
    this.update.emit(this.valueFormControl.toUpsertValue(value));
  }

  private revertValueFormControlToInitialValue() {
    this.valueFormControl.reset(this.valueFormControl.valueRef.value);
  }

  public setFocus(): void {
    if (
      this.valueDefinitionDisplayType &&
      this.valueDefinitionService.isFocusableDisplayField(this.valueFormControl.valueRef)
    ) {
      this.valueFieldRef?.setFocus();
    }
  }

  private handlePendingCreation(): void {
    if (!this.valueFormControl.valueRef.id) {
      this.valueFormControl.waitForNextUpdate();
    }
  }

  public selectFieldForFocus(value: Value, valueGroup: ValueGroup, focusedFieldAction?: FocusedFieldAction): void {
    if (this.isFocusable) {
      this.baseMetricEditorFormStateService.setFocusFieldAndUniqueId(this.focusId, value, valueGroup);
      this.baseMetricEditorFormStateService.setFocusedFieldAction(focusedFieldAction);
    }
  }
}
